import React, { Component, Fragment } from "react";
import EntityDetails from "./EntityDetails";
import GSTDetails from "./GSTDetails";
import ApplicantDetails from "./ApplicantDetails";
import BankDetails from "./BankDetails";
import { RadioBox } from "../../../components";
import Icon from "react-icons-kit";
import { ic_edit } from "react-icons-kit/md/ic_edit";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { goTo } from "../../../utils/utitlity";
import { GET, POST } from "../../../utils/webAPI.service";
import { loaderModal } from "../../../store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  API_PREVIEW_APPLICATION,
  API_SUBMIT_APPLICATION,
} from "../../../utils/APIUrls";
import { get, map } from "lodash";
import { docsColumns } from "./formsPreview.config";
import FormsPreviewContentLoader from "./FormsPreviewContentLoader";
import { Helmet } from "react-helmet";

class FormsPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coApplicantDetails: [],
      loanDetails: {},
      primaryApplicantDetails: {},
      sourcingDetails: {},
      entityDetails: [],
      gstDetails: [],
      bankDetails: [],
      checkList: [],
      comment: "",
      initialResponse: false,
      status: "",
    };
  }

  submitApp = () => {
    let { actions, history, match, userDetails } = this.props,
      { loaderModal } = actions,
      { checkList, comment } = this.state,
      applicationNumber = match.params.appNo;
    loaderModal(true);
    let data = { applicationNumber, questionList: checkList, comment };
    const headers = { "Content-Type": "application/json" };
    POST(API_SUBMIT_APPLICATION, { data }, { headers })
      .then(({ data }) => {
        loaderModal(false);
        if (data.status === "success") {
          alert("Your Application Is Successfully Submitted");
          window.location.reload(true);
          // if (userDetails.roleName === "AGENT") {
          //   history.push("/Sales/dashboard");
          // } else {
          //   history.push("/cluster/dashboard");
          // }
        } else {
          window.alert(data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        loaderModal(false);
        window.alert("Something went wrong please again later");
      });
  };

  checkListToggle = (index, status) => {
    let { checkList } = this.state;
    checkList[index].response = status;
    this.setState({ checkList });
  };

  fetchDetails = () => {
    let { history, match } = this.props,
      applicationNumber = match.params.appNo;
    if (applicationNumber) {
      GET(API_PREVIEW_APPLICATION(applicationNumber))
        .then(({ data }) => {
          this.setState({
            status: get(data, "data.status") || "",
            initialResponse: true,
            entityDetails: get(data, "data.entityDetails") || [],
            coApplicantDetails: get(data, "data.coApplicantDetails") || [],
            loanDetails: get(data, "data.loanDetails") || {},
            primaryApplicantDetails:
              get(data, "data.primaryApplicantDetails") || {},
            sourcingDetails: get(data, "data.sourcingDetails") || {},
            gstDetails: get(data, "data.gstDetails") || [],
            bankDetails: get(data, "data.bankDetails") || [],
            checkList:
              data.data.checkList !== null &&
              get(data, "data.checkList.questionList", []),
            comment: get(data, "data.checkList.comment") || " ",
          });
        })
        .catch((err) => {
          console.log(err);
          window.alert("Something went wrong please try again later.");
        });
    } else {
      history.push("/Sales/gstn");
    }
  };

  setComment = (e) => {
    this.setState({ comment: e.target.value });
  };

  componentDidMount() {
    this.fetchDetails();
  }

  render() {
    const { checkList, initialResponse, status, comment } = this.state,
      { match, userDetails } = this.props,
      applicationNumber = get(match, "params.appNo", ""),
      entityDetails = get(this, "state.entityDetails"),
      coApplicantDetails = get(this, "state.coApplicantDetails"),
      loanDetails = get(this, "state.loanDetails"),
      primaryApplicantDetails = get(this, "state.primaryApplicantDetails"),
      sourcingDetails = get(this, "state.sourcingDetails"),
      gstDetails = get(this, "state.gstDetails"),
      bankDetails = get(this, "state.bankDetails"),
      entityDocuments = map(entityDetails.length && entityDetails, (item) => ({
        name: item.entityName,
        documentsList: item.submittedDocuments
          ? item.submittedDocuments.documentsList
          : [],
      })),
      primaryApplicantDocuments = primaryApplicantDetails.applicantName
        ? {
            name: primaryApplicantDetails.applicantName,
            documentsList: primaryApplicantDetails.submittedDocuments
              ? get(primaryApplicantDetails, "submittedDocuments.documentsList")
              : [],
          }
        : {},
      coApplicantsDocuments = map(
        coApplicantDetails.length && coApplicantDetails,
        (item) => ({
          name: item.applicantName,
          documentsList: item.submittedDocuments
            ? item.submittedDocuments.documentsList
            : [],
        })
      );
    return (
      <div className={"forms-preview-wrapper"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Forms Preview</title>
        </Helmet>
        <div className="content-wrapper">
          {initialResponse ? (
            <div className={"row"}>
              {entityDetails.map((item, index) => {
                return (
                  <EntityDetails
                    key={index}
                    data={item}
                    index={index}
                    status={status}
                    applicationNumber={applicationNumber}
                  />
                );
              })}
              <div className={"col-md-12"}>
                <div className={"box-wrapper border-top"}>
                  <div className={"box-content"}>
                    <div className={"box-header"}>
                      <h3 className={"box-heading"}>
                        Primary Applicant Details
                      </h3>
                      {![
                        "Submit",
                        "Approve",
                        "Escalate",
                        "Hold",
                        "Pending",
                        "Reject",
                      ].includes(status) && (
                        <span
                          onClick={() =>
                            goTo(`/Sales/${applicationNumber}/applicants`)
                          }
                          className={"edit-icon"}
                          title={"Edit"}
                        >
                          <Icon icon={ic_edit} size={18} />
                        </span>
                      )}
                    </div>
                    <div className={"row"}>
                      <ApplicantDetails data={primaryApplicantDetails} />
                    </div>
                  </div>
                </div>
              </div>
              {coApplicantDetails.map((item, index) => {
                return (
                  <div className={"col-xs-12"} key={index}>
                    <div className={"box-wrapper border-top"}>
                      <div className={"box-content"}>
                        <div className={"box-header"}>
                          <h3 className={"box-heading"}>
                            Co-applicant Details {index + 1}
                          </h3>
                          {![
                            "Submit",
                            "Approve",
                            "Escalate",
                            "Hold",
                            "Pending",
                            "Reject",
                          ].includes(status) && (
                            <span
                              onClick={() =>
                                goTo(`/Sales/${applicationNumber}/applicants`)
                              }
                              className={"edit-icon"}
                              title={"Edit"}
                            >
                              <Icon icon={ic_edit} size={18} />
                            </span>
                          )}
                        </div>
                        <div className={"row"}>
                          <ApplicantDetails data={item} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className={"col-xs-12"}>
                <div className={"box-wrapper border-top"}>
                  <div className={"box-content"}>
                    <div className={"box-header"}>
                      <h3 className={"box-heading"}>GST Details</h3>
                      {![
                        "Submit",
                        "Approve",
                        "Escalate",
                        "Hold",
                        "Pending",
                        "Reject",
                      ].includes(status) && (
                        <span
                          onClick={() =>
                            goTo(`/Sales/${applicationNumber}/gstndetails`)
                          }
                          className={"edit-icon"}
                          title={"Edit"}
                        >
                          <Icon icon={ic_edit} size={18} />
                        </span>
                      )}
                    </div>
                    {gstDetails.map((item, index) => {
                      return (
                        <GSTDetails key={index} data={item} index={index} />
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className={"col-xs-12"}>
                <div className={"box-wrapper border-top"}>
                  <div className={"box-content"}>
                    <div className={"box-header"}>
                      <h3 className={"box-heading"}>Banking Details</h3>
                      {![
                        "Submit",
                        "Approve",
                        "Escalate",
                        "Hold",
                        "Pending",
                        "Reject",
                      ].includes(status) && (
                        <span
                          onClick={() =>
                            goTo(`/Sales/${applicationNumber}/banking`)
                          }
                          className={"edit-icon"}
                          title={"Edit"}
                        >
                          <Icon icon={ic_edit} size={18} />
                        </span>
                      )}
                    </div>
                    <div className={"row"}>
                      {bankDetails.map((item, index, array) => {
                        return (
                          <BankDetails
                            key={index}
                            data={item}
                            index={index}
                            length={array.length}
                            status={status}
                            applicationNumber={applicationNumber}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className={"col-xs-12"}>
                <div className={"box-wrapper border-top"}>
                  <div className={"box-content"}>
                    <div className={"box-header"}>
                      <h3 className={"box-heading"}>Documents Submitted</h3>
                      {![
                        "Submit",
                        "Approve",
                        "Escalate",
                        "Hold",
                        "Pending",
                        "Reject",
                      ].includes(status) && (
                        <span
                          onClick={() =>
                            goTo(`/Sales/${applicationNumber}/documents`)
                          }
                          className={"edit-icon"}
                          title={"Edit"}
                        >
                          <Icon icon={ic_edit} size={18} />
                        </span>
                      )}
                    </div>
                    <div className={"row"}>
                      <div className={"col-md-6 col-xs-12"}>
                        <h3 className={"box-heading"}>Entity:</h3>
                        {entityDocuments.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              <h3 className={"box-heading"}>{item.name}</h3>
                              <ReactTable
                                className={
                                  !item.documentsList.length
                                    ? "no-data no-pagination"
                                    : null
                                }
                                data={item.documentsList}
                                columns={docsColumns}
                                showPagination={false}
                                minRows={0}
                                resizable={false}
                                NoDataComponent={() => (
                                  <div className="rt-noData">
                                    No Documents Found
                                  </div>
                                )}
                              />
                            </Fragment>
                          );
                        })}
                      </div>
                      <div className={"col-md-6 col-xs-12"}>
                        <h3 className={"box-heading"}>Primary Applicant:</h3>
                        <h3 className={"box-heading"}>
                          {get(primaryApplicantDocuments, "name", "")}
                        </h3>
                        <ReactTable
                          className={
                            !get(primaryApplicantDocuments, "documentsList", [])
                              .length
                              ? "no-data no-pagination"
                              : null
                          }
                          data={get(
                            primaryApplicantDocuments,
                            "documentsList",
                            []
                          )}
                          columns={docsColumns}
                          showPagination={false}
                          minRows={0}
                          resizable={false}
                          NoDataComponent={() => (
                            <div className="rt-noData">No Documents Found</div>
                          )}
                        />
                      </div>
                      <div className={"col-xs-12"}>
                        <h3 className={"box-heading"}>Secondary Applicant:</h3>
                        {coApplicantsDocuments.map((item, index) => {
                          return (
                            <div key={index} className={"col-md-6 col-xs-12"}>
                              <h3 className={"box-heading"}>{item.name}</h3>
                              <ReactTable
                                className={
                                  !item.documentsList.length
                                    ? "no-data no-pagination"
                                    : null
                                }
                                data={item.documentsList}
                                columns={docsColumns}
                                showPagination={false}
                                minRows={0}
                                resizable={false}
                                NoDataComponent={() => (
                                  <div className="rt-noData">
                                    No Documents Found
                                  </div>
                                )}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"col-xs-12"}>
                <div className={"box-wrapper border-left"}>
                  <div className={"box-content"}>
                    <div className={"box-header"}>
                      <h3 className={"box-heading"}>Application Checklist</h3>
                    </div>
                    <div className={"row box-details"}>
                      <div className={"col-md-8 col-xs-12"}>
                        <ul className={"check-list"}>
                          {checkList?.length && (
                            <Fragment>
                              <li>
                                <label>Yes</label>
                                <label>No</label>
                              </li>
                              {checkList?.map((item, index) => {
                                return (
                                  <li key={index}>
                                    <label>{item?.question}</label>
                                    <span>
                                      <RadioBox
                                        radioColor={
                                          item.response ? "#19ce35" : "#989898"
                                        }
                                        checked={item.response}
                                        name={`question${index + 1}`}
                                        onChange={
                                          ![
                                            "Submit",
                                            "Approve",
                                            "Escalate",
                                            "Hold",
                                            "Pending",
                                            "Reject",
                                          ].includes(status)
                                            ? () =>
                                                this.checkListToggle(
                                                  index,
                                                  true
                                                )
                                            : null
                                        }
                                      />
                                      <RadioBox
                                        radioColor={
                                          !item.response ? "#ed5a5a" : "#989898"
                                        }
                                        checked={!item.response}
                                        name={`question${index + 1}`}
                                        onChange={
                                          ![
                                            "Submit",
                                            "Approve",
                                            "Escalate",
                                            "Hold",
                                            "Pending",
                                            "Reject",
                                          ].includes(status)
                                            ? () =>
                                                this.checkListToggle(
                                                  index,
                                                  false
                                                )
                                            : null
                                        }
                                      />
                                    </span>
                                  </li>
                                );
                              })}
                            </Fragment>
                          )}
                        </ul>
                      </div>
                      {/* <div className={"col-md-4 col-xs-12"}>
                        <label>Comments from Agent</label>
                        <textarea
                          onChange={
                            ![
                              "Submit",
                              "Approve",
                              "Escalate",
                              "Hold",
                              "Pending",
                              "Reject",
                            ].includes(status)
                              ? this.setComment
                              : null
                          }
                          value={comment}
                          rows={8}
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {![
                "Submit",
                "Approve",
                "Escalate",
                "Hold",
                "Pending",
                "Reject",
              ].includes(status) ? (
                <div className={"col-xs-12 text-center"}>
                  <button
                    onClick={this.submitApp}
                    type={"button"}
                    className={"submit-btn"}
                  >
                    submit application
                  </button>
                </div>
              ) : null}
            </div>
          ) : (
            <FormsPreviewContentLoader />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => ({
  userDetails: data.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormsPreview);
