import React, { Component } from "react";
import { connect } from "react-redux";
import { ProgressBar, InputLabelAnimation } from "../../../components";
import Icon from "react-icons-kit";
import { Link } from "react-router-dom";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import GSTDetailsRow from "./GSTDetailsRow";
import { loaderModal } from "../../../store/actions";
import { bindActionCreators } from "redux";
import { getCookie, goTo } from "../../../utils/utitlity";
import { getGSTData, getPanData } from "./GSTNDetails.actions";
import { POST } from "../../../utils/webAPI.service";
import {
  API_DELETE_GST_DATA,
  API_POST_ADD_PAN_FLOW_BUSINESS,
} from "../../../utils/APIUrls";

export class GSTNDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gstinRowArray: [{ screenVisible: "renderUserInput" }],
      panData: {},
      screenVisibleArray: [
        {
          renderUserInput: true,
          renderGstValidate: false,
          renderOtpInput: false,
          renderGSTVerified: false,
        },
      ],
    };
  }

  inputHandler = (e, rowIndex) => {
    const { name, value } = e.target;
    this.setState((prevState) => {
      const gstinRowArray = [...prevState.gstinRowArray];
      gstinRowArray[rowIndex][name] = value;
      return { gstinRowArray };
    });
  };
  //  updateScreenVisible Create a copy of the current screenVisibleArray state
  // Update the screenVisible object for the specific row (rowIndex) with the newScreenVisible object
  // Return the updated state object
  updateScreenVisible = (rowIndex, newScreenVisible) => {
    this.setState((prevState) => {
      const newScreenVisibleArray = [...prevState.screenVisibleArray];
      newScreenVisibleArray[rowIndex] = newScreenVisible;
      return { screenVisibleArray: newScreenVisibleArray };
    });
  };

  deleteRow = (rowIndex, gst, appNo) => {
    const { match, actions } = this.props;
    const { gstinRowArray, screenVisibleArray } = this.state;
    actions.loaderModal(true);
    // The method checks if both gst and appNo are provided.
    if (gst && appNo) {
      const dataToSend = {
        data: {
          applicationNumber: match.params.appNo,
          gstin: gst,
        },
      };
      POST(API_DELETE_GST_DATA, dataToSend)
        .then(({ data }) => {
          if (data?.status === "success") {
            return actions.getGSTData(match?.params?.appNo);
          } else {
            alert(data?.message);
          }
        })
        .then((response) => {
          if (response?.status === "success" && Array.isArray(response.data)) {
            this.setState({
              gstinRowArray: response.data.length > 0 ? response.data : [{}],
              screenVisibleArray: response.data.map(() => ({
                renderUserInput: true,
                renderGstValidate: false,
                renderOtpInput: false,
                renderGSTVerified: false,
              })),
            });
          } else {
            this.setState({
              gstinRowArray: [{}],
              screenVisibleArray: [
                {
                  renderUserInput: true,
                  renderGstValidate: false,
                  renderOtpInput: false,
                  renderGSTVerified: false,
                },
              ],
            });
          }
        })
        .catch((err) => {
          console.error("Error in deleteRow operation:", err);
        })
        .finally(() => {
          actions.loaderModal(false);
        });
    } else {
      const updatedGstinRowArray = gstinRowArray.filter(
        (_, index) => index !== rowIndex
      );
      const updatedScreenVisibleArray = screenVisibleArray.filter(
        (_, index) => index !== rowIndex
      );
      this.setState({
        gstinRowArray:
          updatedGstinRowArray.length > 0 ? updatedGstinRowArray : [{}],
        screenVisibleArray:
          updatedScreenVisibleArray.length > 0
            ? updatedScreenVisibleArray
            : [
                {
                  renderUserInput: true,
                  renderGstValidate: false,
                  renderOtpInput: false,
                  renderGSTVerified: false,
                },
              ],
      });
      actions.loaderModal(false);
    }
  };

  panInput = (e) => {
    const value = e.target.value.toUpperCase();
    this.setState((prevState) => ({
      panData: { ...prevState.panData, businessPan: value, isPanFlow: true },
    }));
  };

  gstDetailsHandler = () => {
    const { panData, isGSTFlow } = this.state;
    const { actions, match } = this.props;
    const dataToSend = {
      data: {
        applicationNumber: match.params.appNo,
        businessId: panData.businessId,
        businessPan: panData.businessPan,
      },
    };
    const regexPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

    if (!(panData.businessPan || isGSTFlow)) {
      return alert("Please enter GST Number OR Business Pan");
    }

    if (!isGSTFlow && panData.isPanFlow) {
      if (regexPan.test(panData.businessPan)) {
        actions.loaderModal(true);
        POST(API_POST_ADD_PAN_FLOW_BUSINESS, dataToSend)
          .then(({ data }) => {
            if (data.status === "success") {
              const { appNo } = match.params;
              goTo(`/Sales/${appNo}/gstnaddress`);
            } else {
              alert(data.message);
            }
            actions.loaderModal(false);
          })
          .catch((err) => {
            actions.loaderModal(false);
            throw err;
          });
      } else {
        alert("Please Enter Correct Pan Number");
      }
    } else {
      const { appNo } = this.props.match.params;
      goTo(`/Sales/${appNo}/gstnaddress`);
    }
  };

  componentDidMount = () => {
    const { actions, match } = this.props;
    let appNo = getCookie("applicationNo");
    actions.getGSTData(appNo).then(({ data }) => {
      if (data && Array.isArray(data)) {
        const gstinRowArray = data.map((entry) => ({
          ...entry,
          screenVisible: "renderUserInput",
        }));
        const screenVisibleArray = data.map(() => ({
          renderUserInput: true,
          renderGstValidate: false,
          renderOtpInput: false,
          renderGSTVerified: false,
        }));
        this.setState({ gstinRowArray, screenVisibleArray });
      }
    });
    actions.getPanData(appNo).then(({ data }) => {
      if (data) {
        this.setState({ panData: data });
      }
    });
  };

  render() {
    const { gstinRowArray, panData } = this.state;
    const { match } = this.props;
    const { appNo } = match.params;
    return (
      <div className="gstn-details__wrappers">
        <ProgressBar active={2} {...this.props} />
        <div className="content-box">
          <h4 className={"box-heading"}>
            Add GST Details or Initiate Non-GST Based Flow
          </h4>
          <div className="card__wrapper">
            <h5>GST Based Flow</h5>
            {/* <button
              className="button__generic"
              onClick={() =>
                this.setState((prevState) => ({
                  gstinRowArray: [...prevState.gstinRowArray, {}], //add new object in row
                  screenVisibleArray: [
                    //Adding a new visibility state object to screenVisibleArray for the new GST entry with default visibility settings.
                    ...prevState.screenVisibleArray,
                    {
                      renderUserInput: true,
                      renderGstValidate: false,
                      renderOtpInput: false,
                      renderGSTVerified: false,
                    },
                  ],
                }))
              }
            >
              + Add another GSTN
            </button> */}
            {gstinRowArray.map((gstinRowData, index) => (
              <GSTDetailsRow
                {...this.props}
                key={`GSTIN-row-${index}`}
                rowIndex={index}
                deleteRow={this.deleteRow}
                gstin={gstinRowData.gstin}
                appNo={gstinRowData?.applicationNumber}
                gstUsername={gstinRowData.gstUsername}
                inputHandler={this.inputHandler}
                panData={panData}
                gstFlowHandler={() => this.setState({ isGSTFlow: true })}
                screenVisible={this.state.screenVisibleArray[index]}
                updateScreenVisible={(newScreenVisible) => {
                  const updatedScreenVisibleArray = [
                    ...this.state.screenVisibleArray,
                  ];
                  updatedScreenVisibleArray[index] = newScreenVisible;
                  this.setState({
                    screenVisibleArray: updatedScreenVisibleArray,
                  });
                }}
              />
            ))}
          </div>
          <div className="card__wrapper">
            <h5>Non-GST Based Flow</h5>
            <div className="d-flex">
              <InputLabelAnimation
                labelText={"Enter Business PAN"}
                required={true}
                maxLength={10}
                name={"businessPan"}
                onChange={this.panInput}
                inputValue={panData.isPanFlow ? panData.businessPan : ""}
                disabled={panData && panData.isPanFlow === false}
              />
            </div>
          </div>
          <div className="text-center mt-2">
            <button className="save-btn" onClick={this.gstDetailsHandler}>
              Save & Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ gstnDetailsReducers }) => ({
  gstnDetails: gstnDetailsReducers.gstnDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      getGSTData,
      getPanData,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GSTNDetails);
