import React, { Component } from "react";
import { ProgressBar } from "../../../components";
import ApplicantForm from "./ApplicantForm";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { Link } from "react-router-dom";
import { loaderModal } from "../../../store/actions";
import { getList } from "../../../store/listData.actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { validateEmail } from "../../../utils/utitlity";
import { POST } from "../../../utils/webAPI.service";
import {
  API_GET_CATEGORY,
  API_GET_GENDER,
  API_GET_MARITAL_STATUS,
  API_GET_QUALIFICATIONS,
  API_GET_RELIGIONS,
  API_GET_RESIDENCE_STATUS,
  API_ADD_PROFILE,
} from "../../../utils/APIUrls";
import { getProfile } from "./applicants.actions";
import get from "lodash/get";
import ContentLoader from "react-content-loader";
import { updateErrorMessage } from "../../../store/actions";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import { Helmet } from "react-helmet";

class Applicants extends Component {
  validateData = (data) => {
    const applicantsBox = this.applicantsBox.children;
    let panList = [];
    for (let i = 0; i < data.length; i++) {
      let { updateErrorMessage } = this.props.actions;
      let {
        pan,
        gender,
        dateOfBirth,
        mobile,
        motherName,
        fatherName,
        name,
        qualification,
        religion,
        category,
        spouseName,
        maritalStatus,
        email,
        currentAddress,
        permanentAddress,
      } = data[i];
      if (!name) {
        updateErrorMessage("Please Enter Applicant/Co-applicant Name.");
        applicantsBox[i].querySelector('input[name="name"]').focus();
        return false;
      }
      // if (!fatherName) {
      //   updateErrorMessage("Please Enter Father' Name");
      //   applicantsBox[i].querySelector('input[name="fatherName"]').focus();
      //   return false;
      // }
      // if (!motherName) {
      //   updateErrorMessage("Please Enter Mother' Name");
      //   applicantsBox[i].querySelector('input[name="motherName"]').focus();
      //   return false;
      // }
      if (!mobile || mobile.length !== 10) {
        updateErrorMessage("Please Enter Valid Mobile Number");
        applicantsBox[i].querySelector('input[name="mobile"]').focus();
        return false;
      }
      if (!dateOfBirth) {
        updateErrorMessage("Please Enter Date of Birth");
        applicantsBox[i].querySelector('input[name="dateOfBirth"]').focus();
        return false;
      }
      if (!gender || gender === "Select Gender") {
        updateErrorMessage("Please Select Gender");
        let elem = applicantsBox[i].querySelector('select[id="gender-select"]');
        elem.scrollIntoView();
        elem.click();
        return false;
      }
      // if (!maritalStatus || maritalStatus === "Select Marital Status") {
      //   updateErrorMessage("Please Select Marital Status");
      //   let elem = applicantsBox[i].querySelector('select[id="marital-status-select"]');
      //   elem.scrollIntoView();
      //   elem.click();
      //   return false;
      // }
      // if (!spouseName && maritalStatus !== "Single") {
      //   updateErrorMessage("Please Enter Spouse Name");
      //   applicantsBox[i].querySelector('input[name="spouseName"]').focus();
      //   return false;
      // }
      // if (!category || category === "Select Category") {
      //   updateErrorMessage("Please Select Category");
      //   let elem = applicantsBox[i].querySelector('select[id="category-select"]');
      //   elem.scrollIntoView();
      //   elem.click();
      //   return false;
      // }
      // if (!religion || religion === "Select Religion") {
      //   updateErrorMessage("Please Select Religion");
      //   let elem = applicantsBox[i].querySelector('select[id="religion-select"]');
      //   elem.scrollIntoView();
      //   elem.click();
      //   return false;
      // }
      // if (!qualification || qualification === "Select Qualification") {
      //   updateErrorMessage("Please Select Qualification");
      //   let elem = applicantsBox[i].querySelector('select[id="qualification-select"]');
      //   elem.scrollIntoView();
      //   elem.click();
      //   return false;
      // }
      if (!pan || pan.length !== 10) {
        updateErrorMessage("Please Enter Valid PAN Number");
        applicantsBox[i].querySelector('input[name="pan"]').focus();
        return false;
      }
      if (panList.includes(pan)) {
        updateErrorMessage("PAN Numbers can not be same of the Applicants");
        applicantsBox[i].querySelector('input[name="pan"]').focus();
        return false;
      } else {
        panList.push(pan);
      }
      if (!email || !validateEmail(email)) {
        updateErrorMessage("Please Enter Valid Email Address");
        applicantsBox[i].querySelector('input[name="email"]').focus();
        return false;
      }
    }
    return true;
  };

  submitForm = () => {
    let { history, profileDetails, match } = this.props,
      { loaderModal } = this.props.actions,
      applicationNumber = get(match, "params.appNo", ""),
      profileList = [];
    for (let i = 0; i < profileDetails.length; i++) {
      profileList.push({
        category: profileDetails[i].category,
        address: profileDetails[i].sameAddress
          ? [{...profileDetails[i].address[0],addressId:profileDetails[i]?.address[0]?.addressId, addressType:"CURRENT" },{...profileDetails[i].address[0],addressId:profileDetails[i]?.address[1]?.addressId,addressType:"PERMANENT"}]
          : [{...profileDetails[i].address[0], addressType:"CURRENT"},{...profileDetails[i].address[1],addressType:"PERMANENT"}],
        dateOfBirth: profileDetails[i].dateOfBirth,
        email: profileDetails[i].email,
        fatherName: profileDetails[i].fatherName,
        gender: profileDetails[i].gender,
        id: profileDetails[i].id,
        maritalStatus: profileDetails[i].maritalStatus,
        mobile: profileDetails[i].mobile,
        motherName: profileDetails[i].motherName,
        name: profileDetails[i].name,
        pan: (get(profileDetails, `[${i}].pan`) || "").toUpperCase(),
        profileType: profileDetails[i].profileType,
        qualification: profileDetails[i].qualification,
        religion: profileDetails[i].religion,
        spouseName: profileDetails[i].spouseName,
      });
    }
    if (this.validateData(profileList)) {
      loaderModal(true);
      let data = { applicationNumber, profileList };
      const headers = { "Content-Type": "application/json" };
      POST(API_ADD_PROFILE, { data }, { headers })
        .then(({ data }) => {
          loaderModal(false);
          if (data.status === "success") {
            history.push(`/Sales/${applicationNumber}/banking`);
          } else {
            this.props.actions.updateErrorMessage(data.message);
          }
        })
        .catch((err) => {
          updateErrorMessage("Something went wrong please try again later.");
          console.log(err);
          loaderModal(false);
        });
    }
  };

  fetchDetails = async () => {
    let {
        genderList,
        qualificationList,
        maritalStatusList,
        residenceStatus,
        religionList,
        categoryList,
        actions,
        history,
        match,
      } = this.props,
      { loaderModal, getList, getProfile } = actions,
      applicationNumber = get(match, "params.appNo", ""),
      err = false;
    if (applicationNumber) {
      loaderModal(true);
      !genderList.length && getList("SET_GENDER_LIST", API_GET_GENDER, "Select Gender");
      !qualificationList.length && getList("SET_QUALIFICATION_LIST", API_GET_QUALIFICATIONS, "Select Qualification");
      !maritalStatusList.length && getList("SET_MARITAL_STATUS", API_GET_MARITAL_STATUS, "Select Marital Status");
      !residenceStatus.length && getList("SET_RESIDENCE_STATUS", API_GET_RESIDENCE_STATUS, "Select Residence Status");
      !religionList.length && getList("SET_RELIGION_LIST", API_GET_RELIGIONS, "Select Religion");
      !categoryList.length && getList("SET_CATEGORY_LIST", API_GET_CATEGORY, "Select Category");
      await getProfile(applicationNumber)
        .then(({ message }) => {
          if (message === "No Profile found for the application number.") {
            err = true;
            history.push(`/Sales/${applicationNumber}/applicantGuide`);
          }
        })
        .catch((err) => {
          console.log(err);
          updateErrorMessage("Something went wrong in on fetching applicants list.");
        });
      !err && loaderModal(false);
    } else {
      history.push("/Sales/gstn");
    }
  };

  componentWillMount() {
    window.scroll(0, 0);
  }

  componentDidMount() {
    this.fetchDetails();
  }

  render() {
    let { profileDetails, match } = this.props,
      applicationNumber = get(match, "params.appNo", "");
    return (
      <div className={"applicants-wrapper"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Applicant</title>
        </Helmet>
        <ProgressBar active={3} {...this.props} />
        <div className={"content-box"}>
          <Link to={`/Sales/${applicationNumber}/applicantGuide`} className={"back-btn-link"}>
            <Icon icon={angleLeft} size={24} /> Back
          </Link>
          <h4 className={"box-heading"}>Add Applicant & Co-applicant Details</h4>
          <div ref={(ref) => (this.applicantsBox = ref)} className={"applicants-box"}>
            {profileDetails.length ? (
              profileDetails.map((item, index) => (
                <ApplicantForm key={index} name={item.profileType === "APPLICANT" ? "app" : "co"} index={index + 1} {...this.props
                } />
              ))
            ) : (
              <div style={{ margin: "25px 0" }}>
                <ContentLoader height={200} type={"facebook"} />
              </div>
            )}
          </div>
          {this.props.errorMessage ? <ErrorMessage>{this.props.errorMessage}</ErrorMessage> : null}
          <div className={"btns-box"}>
            <button onClick={this.submitForm} type={"button"} className={"next-btn"}>
              Save & Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ listData, applicantsReducers, data }) => ({
  maritalStatusList: listData.maritalStatusList,
  genderList: listData.genderList,
  residenceStatus: listData.residenceStatusList,
  categoryList: listData.categoryList,
  religionList: listData.religionList,
  qualificationList: listData.qualificationList,
  profileDetails: applicantsReducers.profileDetails,
  errorMessage: data.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      getList,
      getProfile,
      updateErrorMessage,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Applicants);
