import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { get } from "lodash";
import { Header, LoaderModal } from "../../../components";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Icon from "react-icons-kit";
import { calendar } from "react-icons-kit/fa/calendar";
import { caretDown } from "react-icons-kit/fa/caretDown";
import { cross } from "react-icons-kit/metrize/cross";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Modal from "react-modal";
import { connect } from "react-redux";
import {
  changeValue,
  fetchAllApplications,
  getSearchByFilters,
  setSearchFilterStatus,
  getUserList,
  updateDashBoardData,
  resetData,
} from "./dashboard.action";
import { updateFilterHandler } from "../../../components/Header/header.action";
import { changeValue as headerChangeValue } from "../../..//components/Header/header.action";
import { getDateString, getYMD } from "../../../utils/utitlity";
import { Helmet } from "react-helmet";
import { getDMY, rupeeCommaFormatter } from "../../../utils/utitlity";
import { ic_mode_edit } from "react-icons-kit/md/ic_mode_edit";
import Select from "react-select";
import { loaderModal, checkUserLogin } from "../../../store/actions";
import { API_ASSIGN_APPLICATION_UW } from "../../../utils/APIUrls";
import { POST } from "../../../utils/webAPI.service";
import { fetchDashBoardData } from "./dashboard.action";
import { search } from "react-icons-kit/fa/search";
import moment from "moment";
import { setInputField } from "../../../components/Header/header.action";
class Dashboard extends Component {
  changeUnderwriterStatus = (i) => {
    let list = this.state.underwritersList;
    list[i].selected = !list[i].selected;
    this.setState({ underwritersList: list });
  };
  underwritersModal = (status) => {
    this.setState({
      modalVisible: status,
    });
  };

  handleEvent = async (event, picker) => {
    const { actions } = this.props;
    const startDate = picker.startDate;
    const endDate = picker.endDate;
    actions.changeValue("startDate", startDate);
    actions.changeValue("endDate", endDate);
    this.setState({ isDateApplied: true });
    await this.searchByFiltersHandler(false);
  };

  applicantTabToggle = async (item) => {
    await this.setState({ currentPage: 1, statusFilter: item });
    const { activeTabApplicant, actions, dashboardData = {} } = this.props,
      { param } = dashboardData?.request,
      { key, startDate, endDate } = param || [],
      { roleName, isSearchFilter } = this.state;
    let dataToSend = {
      data: {
        dashboard: roleName,
        pageNumber: 1,
        param: {
          key,
          startDate,
          endDate,
        },
        statusFilter: this.state.statusFilter,
        type: this.props?.searchFilter || "",
      },
    };

    if (activeTabApplicant !== item) {
      actions.changeValue("activeTabApplicant", item);
      await this.props.actions.fetchDashBoardData({ ...dataToSend });
    }
  };

  getPageData = (type) => {
    let { searchFilter, endDate, startDate, searchInput } = this.props;
    let { currentPage, roleName, isSearchFilter } = this.state;
    currentPage = type === "next" ? currentPage + 1 : currentPage - 1;
    this.setState({ currentPage });
    var dataToSend = {
      data: {
        dashboard: roleName,
        pageNumber: currentPage,
        param: {
          key: isSearchFilter ? searchInput?.trim() : null,
          startDate: isSearchFilter
            ? moment(startDate).format("YYYY-MM-DD") === "Invalid date"
              ? ""
              : moment(startDate).format("YYYY-MM-DD")
            : "",
          endDate: isSearchFilter
            ? moment(endDate).format("YYYY-MM-DD") === "Invalid date"
              ? ""
              : moment(endDate).format("YYYY-MM-DD")
            : "",
        },
        statusFilter: this.state.statusFilter,
        type: isSearchFilter ? searchFilter || "" : "",
      },
    };
    this.props.actions.fetchDashBoardData({ ...dataToSend });
  };

  getAllApplications = async (type) => {
    let { searchByFilters, actions } = this.props;
    let { fetchAllApplications, setSearchFilterStatus } = actions;
    !searchByFilters[type] && (await setSearchFilterStatus(type));
    fetchAllApplications();
  };

  rowClicked = (state, rowInfo, column, instance) => {
    if (column && column.Header !== "Edit") {
      return {
        onClick: () => {
          document.cookie = `applicationNumber=${rowInfo.original.caseId}; path=/Credit`;
          var win = window.open(
            `${rowInfo.original.caseId}/overview`,
            "_blank"
          );
          win.focus();
        },
      };
    }
    return false;
  };

  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      currentPage: 1,
      maxDate: new Date(),
      statusFilter: "All",
      isSearchFilter: false,
    };
    props.actions.changeValue("activeTabApplicant", "All");
  }

  componentWillMount() {
    window.scroll(0, 0);
    const { updateFilterHandler, fetchAllApplications } = this.props.actions;
    updateFilterHandler(fetchAllApplications);
    this.props.actions.checkUserLogin().then(({ data, status }) => {
      this.setState({ roleName: data.roleName });
    });
  }

  async componentDidMount() {
    let { userDetails, actions } = this.props,
      { roleName } = userDetails;
    var dataToSend = {
      data: {
        dashboard: roleName,
        pageNumber: 1,
        param: {
          key: "",
          startDate: "",
          endDate: "",
        },
        statusFilter: "All",
        type: "",
      },
    };
    actions.changeValue("startDate", null); // by default startDate and endDate value should be null
    actions.changeValue("endDate", null);
    // this.props.actions.getSearchByFilters();
    this.props.actions.getUserList("UW");
    await this.props.actions.fetchDashBoardData({ ...dataToSend });
    this.setState({ dashboardDataBackup: this.props.dashboardData });
  }
  assignUserHandler = async () => {
    let data = {
      applicationNumber: this.state.applicationNumber,
      userId: this.state.selectedUser,
    };
    let { loaderModal } = this.props.actions;
    loaderModal(true);
    await POST(API_ASSIGN_APPLICATION_UW, { data })
      .then(({ data, status }) => {
        if (data.status === "success") {
          this.setState({ modalVisible: false });
        }
      })
      .catch((err) => {
        throw err;
      });
    await this.getAllApplications("byTabs");
    loaderModal(false);
  };
  searchByFiltersHandler = (clearfilter = false) => {
    let { searchFilter, searchInput, endDate, startDate, actions } = this.props,
      { roleName, isSearchFilter } = this.state;
    if (!this.state.isDateApplied) {
      endDate = startDate = null;
    }
    if (!searchInput) {
      searchInput = null;
    }

    if (!clearfilter) {
      if (
        searchFilter !== null &&
        searchFilter !== "" &&
        searchInput === null
      ) {
        alert("Please enter search input");
        return;
      }
    }
    var dataToSend = {
      data: {
        dashboard: roleName,
        pageNumber: 1,
        param: {
          key: searchInput?.trim(),
          startDate:
            moment(startDate).format("YYYY-MM-DD") === "Invalid date"
              ? ""
              : moment(startDate).format("YYYY-MM-DD"),

          endDate:
            moment(endDate).format("YYYY-MM-DD") === "Invalid date"
              ? ""
              : moment(endDate).format("YYYY-MM-DD"),
        },
        statusFilter: this.state.statusFilter,
        type: searchFilter || "",
      },
    };
    actions.fetchDashBoardData({ ...dataToSend });
    this.setState({ currentPage: 1, isSearchFilter: true }); //after search filter current page should be always one.
  };
  clearFilterHandler = async () => {
    let { actions } = this.props;
    let { setInputField } = this.props.actions;
    actions.changeValue("startDate", null); // by clicking clear Filter startDate and endDate value set to be null
    actions.changeValue("endDate", null);
    actions.headerChangeValue("searchInput", "");
    actions.headerChangeValue("searchFilter", "");
    setInputField(false);
    await this.props.actions.resetData(this.state.dashboardDataBackup);
    await this.searchByFiltersHandler(true);
  };
  render() {
    let { modalVisible } = this.state || {};
    const { applicationLoader, endDate, startDate, dashboardData } = this.props,
      data = get(dashboardData, "searchResponse") || [],
      tabsData = get(dashboardData, "uw") || [],
      activeTabApplicant = get(this, "props.activeTabApplicant", "All");
    const dataTableHeaders = [
      {
        Header: "Date",
        accessor: "",
        Cell: ({ original }) => getDMY(new Date(original.date)),
      },
      {
        Header: "Application Id",
        accessor: "caseId",
      },
      {
        Header: "Application Name",
        accessor: "applicationName",
        width: 150,
      },
      {
        Header: "Primary Industry",
        accessor: "primaryIndustry",
      },
      {
        Header: "PA (CIBIL Score)",
        accessor: "cibilScore",
      },
      {
        Header: "CC Score",
        accessor: "ccScore",
      },
      {
        Header: "Turnover",
        accessor: "turnOver",
        Cell: (props) => {
          return rupeeCommaFormatter(props.value);
        },
      },
      {
        Header: "Loan Amount",
        accessor: "loanAmount",
        Cell: (props) => {
          return rupeeCommaFormatter(props.value);
        },
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Underwriter",
        accessor: "underWriter",
      },
    ];
    const dataTableHeadersAdmin = [
      ...dataTableHeaders,
      {
        Header: "Edit",
        accessor: "edit",
        Cell: ({ original }) => {
          return (
            <Icon
              icon={ic_mode_edit}
              size={20}
              onClick={() =>
                this.setState({
                  modalVisible: true,
                  applicationNumber: original.caseId,
                })
              }
            />
          );
        },
      },
    ];
    let userList = [];
    if (this.props.userList) {
      userList = this.props.userList.map((el, index) => {
        if (el.middleName === null) {
          const name = el.firstName + " " + el.lastName;
          return {
            value: el.userId,
            label: name,
          };
        } else {
          const name = el.firstName + " " + el.middleName + " " + el.lastName;
          return {
            value: el.userId,
            label: name,
          };
        }
      });
    }
    return (
      <div className={"dashboard-wrapper"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dashboard</title>
        </Helmet>
        <Header
          {...this.props}
          searchByFiltersHandler={this.searchByFiltersHandler.bind(this, false)}
        />
        <LoaderModal status={applicationLoader} />
        <Modal
          parentSelector={() => document.querySelector(".dashboard-wrapper")}
          isOpen={modalVisible}
          onRequestClose={null}
          className={"underwriters-modal"}
          overlayClassName={"content-modal-overlay"}
        >
          <div className={"box-header"}>
            <h4>Please select the name of underwriter for case allocation. </h4>
            <span
              onClick={() => this.underwritersModal(false)}
              className={"cross-icon"}
            >
              <Icon icon={cross} size={22} />
            </span>
          </div>
          <div className={"underwriters-box"}>
            <h3>Underwriter</h3>
            <Select
              options={userList}
              onChange={(e) => this.setState({ selectedUser: e.value })}
            />
          </div>
          <div className={"text-center"}>
            <button type={"button"} onClick={this.assignUserHandler}>
              Done
            </button>
          </div>
        </Modal>
        <div className={"daterange-bar-wrapper"}>
          <div className={"container"}>
            <div className={"daterange-box"}>
              <span>Search by Date</span>
              <DateRangePicker
                onApply={this.handleEvent}
                showDropdowns
                changeYear={false}
                linkedCalendars={false} // Disables linked calendars
                // maxDate={this.state.maxDate}
              >
                <button>
                  <span className={"icons"}>
                    <Icon icon={calendar} size={16} />
                  </span>
                  <span>
                    {getDateString(startDate)} - {getDateString(endDate)}
                  </span>
                  <span className={"icons"}>
                    <Icon icon={caretDown} size={16} />
                  </span>
                </button>
              </DateRangePicker>
              {/* <span
                className={"search-icon"}
                onClick={this.searchByFiltersHandler}
              >
                <Icon icon={search} size={16} />
              </span> */}
              <div
                className="clear-filter ml-3"
                onClick={this.clearFilterHandler}
              >
                Clear All
              </div>
            </div>
          </div>
        </div>
        <div className={"container"}>
          <div className={"applicants-list-wrapper"}>
            <ul className={"list-unstyled"}>
              <li
                onClick={() => this.applicantTabToggle("All")}
                className={activeTabApplicant === "All" ? "active" : null}
              >
                <span>{get(tabsData, "all", 0)}</span>
                <span>All Applications</span>
              </li>
              <li
                onClick={() => this.applicantTabToggle("Approved")}
                className={activeTabApplicant === "Approved" ? "active" : null}
              >
                <span>{get(tabsData, "approved", 0)}</span>
                <span>Approved Applications</span>
              </li>
              <li
                onClick={() => this.applicantTabToggle("Pending")}
                className={activeTabApplicant === "Pending" ? "active" : null}
              >
                <span>{get(tabsData, "pending", 0)}</span>
                <span>Pending Applications</span>
              </li>
              <li
                onClick={() => this.applicantTabToggle("Overdue")}
                className={activeTabApplicant === "Overdue" ? "active" : null}
              >
                <span>{get(tabsData, "overdue", 0)}</span>
                <span>Overdue Applications</span>
              </li>
              <li
                onClick={() => this.applicantTabToggle("Rejected")}
                className={activeTabApplicant === "Rejected" ? "active" : null}
              >
                <span>{get(tabsData, "rejected", 0)}</span>
                <span>Rejected Applications</span>
              </li>
            </ul>
          </div>
          <div className={"datatable-wrapper container"}>
            <ReactTable
              className={!data.length ? "no-data no-pagination" : null}
              data={data}
              columns={
                this.state.roleName === "UW_ADMIN"
                  ? dataTableHeadersAdmin
                  : dataTableHeaders
              }
              defaultPageSize={10}
              showPagination={false}
              resizable={false}
              minRows={0}
              getTdProps={this.rowClicked}
            />
            <ul className={"datatable-pagination"}>
              <li>
                <button
                  disabled={this.state.currentPage < 2}
                  className={"prev-btn"}
                  type={"button"}
                  onClick={() => this.getPageData("prev")}
                >
                  Previous
                </button>
              </li>
              <li>
                <span>
                  Page No. {this.state.currentPage} of{" "}
                  {get(dashboardData, "totalPage", 1)}
                </span>
              </li>
              <li>
                <button
                  disabled={
                    get(dashboardData, "totalPage", 1) ===
                    this.state.currentPage
                  }
                  className={"next-btn"}
                  type={"button"}
                  onClick={() => this.getPageData("next")}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ creditDashboard, header, data }) => ({
  applicationLoader: creditDashboard.applicationLoader,
  applications: get(creditDashboard, "allApplications"),
  startDate: get(
    creditDashboard,
    "form.values.startDate",
    getYMD(new Date().toLocaleDateString())
  ),
  endDate: get(
    creditDashboard,
    "form.values.endDate",
    getYMD(new Date().toLocaleDateString())
  ),
  activeTabApplicant: get(
    creditDashboard,
    "form.values.activeTabApplicant",
    ""
  ),
  searchByFilters: creditDashboard.searchByFilters,
  userList: creditDashboard.userList,
  assignResponse: creditDashboard.assignResponse,
  dashboardData: creditDashboard.dashboardData,
  searchInput: get(header, "form.values.searchInput", ""),
  searchFilter: get(header, "form.values.searchFilter", ""),
  userDetails: data.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchAllApplications,
      getSearchByFilters,
      updateFilterHandler,
      headerChangeValue,
      changeValue,
      setSearchFilterStatus,
      getUserList,
      loaderModal,
      checkUserLogin,
      fetchDashBoardData,
      updateDashBoardData,
      resetData,
      setInputField,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
