import React, { Component, Fragment } from "react";
import { Header, LoaderModal, InputLabelAnimation } from "../../components";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import {
  loaderModal,
  setUserDetails,
  setUserLoginStatus,
} from "../../store/actions";
import {
  API_GENERATE_TOKEN,
  API_GET_APPLICATION_NUMBER,
  API_GET_SALES_ALL_APPLICATIONS,
  API_LOGIN,
} from "../../utils/APIUrls";
import { bindActionCreators } from "redux";
import { getCookie, validateEmail } from "../../utils/utitlity";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { BASE_URL, GET, POST } from "../../utils/webAPI.service";
class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isFlipped: false,
      error: "",
    };
  }

  setEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  setPassword = (e) => {
    this.setState({ password: e.target.value });
  };

  fetchApplications = async (lender) => {
    try {
      const SendData = {
        pageNumber: 1,
        searchBy: "",
        searchString: lender,
        statusFilter: "All",
        endDate: "",
        startDate: "",
      };
      const { data } = await POST(
        API_GET_SALES_ALL_APPLICATIONS,
        { data: SendData },
        { "Content-Type": "application/json" }
      );
      return data;
    } catch (err) {
      console.log(err);
      window.alert("Something went wrong, Please try again later.");
      this.props.actions.loaderModal(false);
      throw err;
    }
  };

  addNewApplication = () => {
    const { history, match } = this.props,
      custId = match.params.custId || null;
    GET(API_GET_APPLICATION_NUMBER(custId))
      .then(({ data }) => {
        if (data.status === "success") {
          document.cookie = `applicationNumber=${data.data}; path=/Sales`;
          // history.push(`/Sales/${data.data}/sourcingDetails`);
        }
      })
      .catch((err) => {
        this.setState({
          error: "Something went wrong please try again later.",
        });
      });
  };

  stages = (subStatus) => {
    let { history, actions } = this.props;
    let appNo = getCookie("applicationNo");
    if (subStatus === "Start") {
      return history.push(`/Sales/${appNo}/post_login`);
    } else if (subStatus === "Sourcing Details") {
      return history.push(`/Sales/${appNo}/sourcingDetails`);
    } else if (subStatus === "Loan Details") {
      return history.push(`/Sales/${appNo}/gstn`);
    } else if (subStatus === "GST Initiated") {
      return history.push(`/Sales/${appNo}/gstndetails`);
    } else if (subStatus === "/Sales/GST Validated") {
      return history.push(`/Sales/${appNo}/gstndetails`);
    } else if (subStatus === "Business") {
      return history.push(`/Sales/${appNo}/gstnaddress`);
    } else if (subStatus === "Profile") {
      return history.push(`/Sales/${appNo}/applicantGuide`);
    } else if (subStatus === "Banking") {
      return history.push(`/Sales/${appNo}/banking`);
    } else if (subStatus === "Documents") {
      return history.push(`/Sales/${appNo}/documents`);
    } else if (subStatus === "Reference") {
      return history.push(`/Sales/${appNo}/references`);
    } else {
      return history.push(`/Sales/${appNo}/forms_preview`);
    }
  };

  loginSubmitForm = async (e) => {
    e.preventDefault();

    let { history, actions } = this.props,
      { loaderModal, setUserDetails, setUserLoginStatus } = actions,
      { email, password } = this.state;

    const baseUrl = `${process.env.REACT_APP_BASE_URL}${BASE_URL}`;

    if (!validateEmail(email)) {
      this.setState({
        error: "Please Enter Correct Email Id or Mobile Number",
      });
      return;
    }

    try {
      loaderModal(true);

      const { data: tokenData } = await axios.get(
        `${baseUrl}${API_GENERATE_TOKEN}`
      );
      document.cookie = `token=${tokenData.data.tokenId}; path=/`;

      const headers = {
        "Content-Type": "application/json",
        tokenId: tokenData.data.tokenId,
      };

      const { data: loginData } = await axios.post(
        `${baseUrl}${API_LOGIN}`,
        {
          data: {
            email: email,
            password: password,
            tokenId: tokenData.data.tokenId,
          },
        },
        { headers }
      );
      if (loginData.status === "success") {
        document.cookie = `lenderName=${loginData?.data?.lenderName}; path=/`;
        const applicationsData = await this.fetchApplications(
          loginData?.data?.lenderName
        );
        document.cookie = `applicationNo=${applicationsData?.data?.searchResponse[0]?.caseId}; path=/`;
        setUserDetails(loginData.data);
        setUserLoginStatus(true);
        loaderModal(false);
        if (
          loginData.data.roleName === "UW" ||
          loginData.data.roleName === "UW_ADMIN"
        ) {
          history.push("/Credit/dashboard");
        } else if (loginData.data.roleName === "CPO_USER") {
          history.push("/CPODashboard/User?activetab=new");
        } else if (loginData.data.roleName === "ADMIN") {
          history.push("/Admin");
        } else if (loginData.data.roleName === "CLUSTER_USER") {
          history.push("/cluster/dashboard");
        } else if (loginData.data.roleName === "LEAD_MANAGER") {
          history.push("/lead_manager/dashboard");
        } else if (loginData.data.roleName === "CPO_ADMIN") {
          history.push("/cpopanelAdmin?activetab=Unassigned");
        } else {
          this.stages(applicationsData?.data?.searchResponse[0]?.subStatus);
        }
      } else {
        this.setState({ error: loginData?.message });
      }
    } catch (err) {
      loaderModal(false);
      console.log(err);
      loaderModal(false);
      this.setState({
        error: "Something went wrong please try again later.",
      });
    }
    loaderModal(false);
  };

  // componentWillMount() {
  //   window.scroll(0, 0);
  //   let { isLogin, history } = this.props;
  //   isLogin && history.push('/Sales/dashboard');
  // }

  render() {
    let { email, password } = this.state,
      { loaderModalStatus } = this.props;
    return (
      <Fragment>
        <LoaderModal status={loaderModalStatus} />
        <Header {...this.props} />
        <div className={"content-wrapper login-wrapper"}>
          <div className={"content-box"}>
            <h2 className={"heading"}>Login to your Account</h2>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <form onSubmit={this.loginSubmitForm} className={"form-box"}>
                  <InputLabelAnimation
                    labelText={"Enter Login Id"}
                    onChange={this.setEmail}
                    name={"email"}
                    tabIndex={1}
                    inputValue={email}
                    required={true}
                  />
                  <InputLabelAnimation
                    labelText={"Password"}
                    name={"password"}
                    tabIndex={2}
                    onChange={this.setPassword}
                    type={"password"}
                    inputValue={password}
                    required={true}
                  />
                  <div className={"actions-box"}>
                    {this.state.error ? (
                      <ErrorMessage>{this.state.error}</ErrorMessage>
                    ) : null}
                    <button tabIndex={3} type={"submit"}>
                      Login
                    </button>
                    <Link to={"/forgot_password"} className={"primary-link"}>
                      Forgot Password?
                    </Link>
                    <Link to={"/signup"} className={"primary-link"}>
                      Don’t have an account? Sign Up here.
                    </Link>
                  </div>
                </form>
              </div>
              <div className={"col-md-6"}>
                <img
                  src={require("../../assets/images/laptop.svg")}
                  alt={"laptop_img"}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  isLogin: data.isLogin,
  loaderModalStatus: data.loaderModal,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      setUserDetails,
      setUserLoginStatus,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
