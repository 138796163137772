import React, { Fragment } from 'react';
import get from 'lodash/get';

const ApplicantDetails = ({ data }) => {
  return (
    <Fragment>
      <div className={"col-xs-12 col-md-4"}>
        <div className={"row"}>
          <div className={"col-xs-6 col-md-6 box-details"}>
            <div>
              <label>Applicant Name</label>
              <span>{get(data, "applicantName", "")}</span>
            </div>
          </div>
          <div className={"col-xs-6 col-md-6 box-details"}>
            <div className="overflow-break-word">
              <label>Address</label>
              <span>{get(data, "address", "")}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={"col-xs-12 col-md-8 vertical-divider-left"}>
        <div className={"row box-details"}>
          <div className={"col-xs-6 col-md-2"}>
            <label>DOB</label>
            <span>{get(data, "dateOfBirth", "")}</span>
          </div>
          <div className={"col-xs-6 col-md-2"}>
            <label>PAN</label>
            <span>{get(data, "pan", "")}</span>
          </div>
          <div className={"col-xs-6 col-md-2"}>
            <label>Age</label>
            <span>{get(data, "age", "")}</span>
          </div>
          {/* <div className={'col-xs-6 col-md-3'}>
            <label>Education</label>
            <span>{get(data, 'education', '')}</span>
          </div> */}
          <div className={"col-xs-6 col-md-3"}>
            <label>House Ownership</label>
            <span>{get(data, "houseOwnership", "")}</span>
          </div>
          <div className={"col-xs-6 col-md-3"}>
            <label>Share Holding</label>
            <span>{get(data, "shareHolding", "")}</span>
          </div>
          <div className={"col-xs-6 col-md-2"}>
            <label>CIBIL Match</label>
            <span>{get(data, "cibilMatch", "NA")}</span>
          </div>
          <div className={"col-xs-6 col-md-2"}>
            <label>Phone</label>
            <span>{get(data, "phone", "")}</span>
          </div>
          <div className={"col-xs-6 col-md-5"}>
            <label>Email</label>
            <span>{get(data, "email", "")}</span>
          </div>
        </div>
        {/* <div className={' box-details p-0'}> */}

        {/* </div> */}
      </div>
    </Fragment>
  );
};

export default ApplicantDetails;
