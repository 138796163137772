import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { POST } from "../../../utils/webAPI.service";
import { connect } from "react-redux";
import { getYMD, getDateString } from "../../../utils/utitlity";
import { bindActionCreators } from "redux";
import { loaderModal } from "../../../store/actions";
import { dataTableHeaders } from "./Dashboard.config";
import { API_GET_SALES_ALL_APPLICATIONS } from "../../../utils/APIUrls";
import { changeValue as headerChangeValue, updateFilterHandler } from "../../../components/Header/header.action";
import { get } from "lodash";
import { Helmet } from "react-helmet";

class Members extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: getDateString(new Date()),
      endDate: getDateString(new Date()),
      activeTabApplicant: "All",
      currentPage: 1,
      totalPages: 1,
      applicationsData: [],
      prevBtn: true,
      nextBtn: true,
      allApplicationCount: 0,
      inCompletedApplicationCount: 0,
      pendingWithCreditApplicationCount: 0,
      closedApplicationsCount: 0,
      submittedApplicationCount: 0,
    };
  }
  fetchApplications = () => {
    let { activeTabApplicant, currentPage } = this.state,
      { loaderModalStatus, headerSearch, actions } = this.props,
      { loaderModal, headerChangeValue } = actions;
    !loaderModalStatus && loaderModal(true);
    const searchBy = get(headerSearch, "searchFilter.value", ""),
      searchString = searchBy === "" ? "" : get(headerSearch, "searchInput", "");
    let startDate = getYMD(new Date(this.state.startDate).toLocaleDateString()),
      endDate = getYMD(new Date(this.state.endDate).toLocaleDateString());
    const requestPayload = { pageNumber: currentPage };
    let { searchByFilters } = this.props;
    if (searchString) {
      requestPayload.searchBy = searchBy;
      requestPayload.searchString = searchString;
      requestPayload.statusFilter = "";
      requestPayload.endDate = "";
      requestPayload.startDate = "";
    } else if (searchByFilters.byTabs) {
      requestPayload.statusFilter = activeTabApplicant;
      requestPayload.searchBy = "";
      requestPayload.searchString = "";
      requestPayload.endDate = "";
      requestPayload.startDate = "";
    } else if (searchByFilters.byDate) {
      requestPayload.endDate = endDate;
      requestPayload.startDate = startDate;
      requestPayload.searchBy = "";
      requestPayload.searchString = "";
      requestPayload.statusFilter = "";
    }
    POST(API_GET_SALES_ALL_APPLICATIONS, { data: requestPayload }, { "Content-Type": "application/json" })
      .then(({ data }) => {
        loaderModal(false);
        if (data.status === "success") {
          this.setState({
            totalPages: get(data, "data.totalPage") || 1,
            applicationsData: get(data, "data.searchResponse") || [],
            prevBtn: get(data, "data.previousFlag") || false,
            nextBtn: get(data, "data.nextFlag") || false,
            allApplicationCount: get(data, "data.allApplicationCount") || 0,
            inCompletedApplicationCount: get(data, "data.inCompletedApplicationCount") || 0,
            pendingWithCreditApplicationCount: get(data, "data.pendingWithCreditApplicationCount") || 0,
            closedApplicationsCount: get(data, "data.closedApplicationsCount") || 0,
            submittedApplicationCount: get(data, "data.submittedApplicationCount") || 0,
          });
        } else if (!data.message.trim().search("Application not found")) {
          this.setState({
            applicationsData: [],
            totalPages: 1,
            prevBtn: false,
            nextBtn: false,
            allApplicationCount: 0,
            inCompletedApplicationCount: 0,
            pendingWithCreditApplicationCount: 0,
            closedApplicationsCount: 0,
            submittedApplicationCount: 0,
          });
        }
      })
      .catch((err) => {
        window.alert("Something went wrong, Please try again later.");
        loaderModal(false);
      })
      .finally(() => {
        headerChangeValue("searchInput", "");
        headerChangeValue("searchFilter", "");
      });
  };

  getPageData = (type) => {
    let count = this.state.currentPage;
    if (type === "next") {
      this.setState(
        {
          currentPage: ++count,
        },
        () => {
          this.fetchApplications();
        }
      );
    } else if (type === "prev") {
      this.setState(
        {
          currentPage: --count,
        },
        () => {
          this.fetchApplications();
        }
      );
    }
  };

  rowClicked = (state, rowInfo) => {
    let { history } = this.props;
    if (rowInfo && rowInfo.row) {
      return {
        onClick: () => {
          const subStatus = rowInfo.original.subStatus;
          if (subStatus === "Start") {
            history.push(`/Sales/post_login`);
          } else if (subStatus === "Sourcing Details") {
            history.push(`/Sales/${rowInfo.original.caseId}/sourcingDetails`);
          } else if (subStatus === "Loan Details") {
            history.push(`/Sales/${rowInfo.original.caseId}/gstn`);
          } else if (subStatus === "GST Initiated") {
            history.push(`/Sales/${rowInfo.original.caseId}/gstndetails`);
          } else if (subStatus === "GST Validated") {
            history.push(`/Sales/${rowInfo.original.caseId}/gstndetails`);
          } else if (subStatus === "Business") {
            history.push(`/Sales/${rowInfo.original.caseId}/gstnaddress`);
          } else if (subStatus === "Profile") {
            history.push(`/Sales/${rowInfo.original.caseId}/applicantGuide`);
          } else if (subStatus === "Banking") {
            history.push(`/Sales/${rowInfo.original.caseId}/banking`);
          } else if (subStatus === "Documents") {
            history.push(`/Sales/${rowInfo.original.caseId}/documents`);
          } else if (subStatus === "Reference") {
            history.push(`/Sales/${rowInfo.original.caseId}/forms_preview`);
          } else {
            history.push(`/Sales/${rowInfo.original.caseId}/forms_preview`);
          }
        },
      };
    }
    return false;
  };

  componentWillMount() {
    window.scroll(0, 0);
  }

  componentDidMount() {
    this.fetchApplications();
  }

  render() {
    let { applicationsData, totalPages, currentPage, nextBtn, prevBtn } = this.state;
    return (
      <div className={"dashboard-wrapper"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dashboard</title>
        </Helmet>
        <div className={"container mt-3"}>
          <div className={"datatable-wrapper"}>
            <ReactTable
              className={!applicationsData.length ? "no-data no-pagination" : null}
              data={applicationsData}
              columns={dataTableHeaders}
              defaultPageSize={10}
              showPagination={false}
              resizable={false}
              minRows={0}
              getTrProps={this.rowClicked}
            />
            <ul className={"datatable-pagination"}>
              <li>
                <button
                  disabled={!prevBtn}
                  className={"prev-btn"}
                  type={"button"}
                  onClick={() => this.getPageData("prev")}
                >
                  Previous
                </button>
              </li>
              <li>
                <span>
                  Page No. {currentPage} of {totalPages}
                </span>
              </li>
              <li>
                <button
                  disabled={!nextBtn}
                  className={"next-btn"}
                  type={"button"}
                  onClick={() => this.getPageData("next")}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data, header, salesDashboard }) => ({
  loaderModalStatus: data.loaderModal,
  headerSearch: get(header, "form.values"),
  searchByFilters: salesDashboard.searchByFilters,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      updateFilterHandler,
      headerChangeValue,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Members);
