import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header/Header";
import { connect } from "react-redux";
import Modal from "react-modal";
import Icon from "react-icons-kit";
import { cross } from "react-icons-kit/metrize/cross";
import { ic_play_circle_outline } from "react-icons-kit/md/ic_play_circle_outline";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%,-50%)",
    height: "380px",
    width: "600px",
    overflowY: "hidden",
    paddingTop: "0px",
  },
};
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      activeVideo: "",
    };
  }

  toggleModal = (name) => {
    this.setState({ modalVisible: true, activeVideo: name });
  };

  componentWillMount() {
    window.scroll(0, 0);
    let { isLogin, history } = this.props;
    // isLogin && history.push("/Sales/dashboard");
  }

  render() {
    let { modalVisible, activeVideo } = this.state;
    return (
      <Fragment>
        <Modal
          style={customStyles}
          isOpen={modalVisible}
          contentLabel="Video Modal"
          overlayClassName={"content-modal-overlay"}
        >
          <span
            onClick={() => this.setState({ modalVisible: false })}
            className={"cross-icon"}
          >
            <Icon icon={cross} size={26} />
          </span>
          {activeVideo === "video1" ? (
            <iframe
              src="https://player.vimeo.com/video/365210996?autoplay=1"
              width="100%"
              height="100% "
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
              title="Pemant"
            ></iframe>
          ) : (
            <iframe
              src="https://player.vimeo.com/video/362026618?autoplay=1"
              width="100%"
              height="100% "
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
              title="Pemant"
            ></iframe>
          )}
        </Modal>
        <Header {...this.props} />
        <div className={"content-wrapper preview-wrapper"}>
          <div className={"content-box"}>
            <h3 className={"heading"}>Welcome to Onboarding </h3>
            <h3 className={"heading"}>Dashboard</h3>
            {/* <h5 className={"sub-heading"}>Login if you are a new user.</h5> */}
            <div className={"btns-box"}>
              <Link to={"/login"}>Login</Link>
              <span>Or</span>
              <Link to={"/signup"}>Sign Up</Link>
            </div>
            <div className={"video-preview-wrapper"}>
              {/* <h5 className={"sub-heading"}>See how it works.</h5> */}
              {/* <div className={"videos-preview-box"}>
                <div onClick={() => this.toggleModal("video1")} className={"list-item"}>
                  <Icon icon={ic_play_circle_outline} className={"play-icon"} size={64} />
                  <img src={require("../assets/images/index_thumbnail1.JPG")} alt={"video_preview"} />
                </div>
                <div onClick={() => this.toggleModal("video2")} className={"list-item"}>
                  <Icon icon={ic_play_circle_outline} className={"play-icon"} size={64} />
                  <img src={require("../assets/images/index_thumbnail2.jpg")} alt={"video_preview"} />
                </div>
              </div> */}
            </div>
          </div>
          <div className={"content-box"}>
            <img
              src={require("../assets/images/laptop.svg")}
              alt={"laptop_img"}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  isLogin: data.isLogin,
});

export default connect(mapStateToProps)(Index);
