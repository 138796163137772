import React, { Component } from "react";
import { Link } from "react-router-dom";
import { InputLabelAnimation, DatePicker } from "../../../components";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import Icon from "react-icons-kit";
import { ic_add } from "react-icons-kit/md/ic_add";
import Address from "./Address";
import PartyDetails from "./PartyDetails";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get } from "lodash";
import { getList } from "../../../store/listData.actions";
import {
  API_GET_CONSTITUTION,
  API_ADD_BUSINESS_DETAILS,
  API_GET_TAXPAYER_TYPE,
  API_GET_BUSINESS_ADDRESS_TYPE,
  API_POST_UPDATE_GST_DATA,
} from "../../../utils/APIUrls";
import { POST } from "../../../utils/webAPI.service";
import {
  changeInputValue,
  addAddress,
  updateAddress,
  deleteAddress,
  addPartyNewRow,
  deletePartyRow,
  partyChangeHandler,
} from "./GstnAddress.actions";

import { getDMY } from "../../../utils/utitlity";
import { loaderModal } from "../../../store/actions";
import { ic_check_circle } from "react-icons-kit/md/ic_check_circle";
import { postPartyDetails } from "./GSTAddress.config";

class GstnUpdatedAddress extends Component {
  state = {
    addressDetailsRow: [{}],
    partyDetailsRow: [{}],
    maxDate: new Date(),
  };
  submitForm = (e) => {
    e.preventDefault();
    let { actions, history, match, businessDetailsData, partyDetailsData } = this.props,
      { gstDetails, ...requiredData } = businessDetailsData;
    const applicationNumber = match.params.appNo;
    let dataToSend = {
      data: {
        ...requiredData,
        applicationNumber,
      },
    };
    actions.loaderModal(true);
    POST(API_ADD_BUSINESS_DETAILS, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          let partyDataToSend = {
            data: {
              applicationNumber: applicationNumber,
              relatedParty: partyDetailsData,
            },
          };
          postPartyDetails(partyDataToSend).then((data) => {
            if (data.status === "success") {
              history.push(`/Sales/${applicationNumber}/applicantGuide`);
            } else {
              alert(data.message);
            }
          });
        } else {
          alert(data.message);
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        this.setState({
          error: "Something went wrong please try again later.",
        });
        actions.loaderModal(false);
        throw err;
      });
    return null;
  };
  inputHandler = (e) => {
    let { actions } = this.props,
      { value, name } = e.target;
    actions.changeInputValue(value, name);
  };
  dateHandler = (name, value) => {
    let { actions } = this.props;
    actions.changeInputValue(value, name);
  };
  addGSTData = (gstIndex) => {
    let { actions, businessDetailsData, match } = this.props,
      dataToSend = {
        data: { ...businessDetailsData.gstDetails[gstIndex], applicationNumber: match.params.appNo },
      };
    actions.loaderModal(true);
    POST(API_POST_UPDATE_GST_DATA, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          this.setState({ [`gstSubmit${gstIndex}`]: true });
        } else {
          alert(data.message);
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        actions.loaderModal(false);
        throw err;
      });
  };

  render() {
    let {
        businessDetailsData,
        businessConstitutionList,
        match,
        taxPayerTypeList,
        addressTypeList,
        actions,
        partyDetailsData,
      } = this.props,
      { maxDate } = this.state,
      {
        addAddress,
        updateAddress,
        deleteAddress,
        changeInputValue,
        addPartyNewRow,
        deletePartyRow,
        partyChangeHandler,
      } = actions;

    return (
      <div className={"gstn-updated-wrapper"}>
        <div className={"content-box"}>
          <Link
            to={`/Sales/${match.params.appNo}/gstndetails`}
            className={"back-btn-link"}
          >
            <Icon icon={angleLeft} size={24} /> Back
          </Link>
          <h4 className={"box-heading"}>
            Verify details and mark all addresses
          </h4>
          <form onSubmit={this.submitForm}>
            <div className="row">
              <div className={"col-md-4"}>
                <InputLabelAnimation
                  labelText={"Legal Name"}
                  required={true}
                  type={"text"}
                  name={"businessName"}
                  inputValue={get(businessDetailsData, "businessName", "")}
                  onChange={this.inputHandler}
                />
              </div>
              <div className={"col-md-4"}>
                <InputLabelAnimation
                  labelText={"Trade Name"}
                  required={true}
                  type={"text"}
                  name={"businessName"}
                  onChange={this.inputHandler}
                  inputValue={get(businessDetailsData, "businessName", "")}
                />
              </div>
              <div className={"col-md-4"}>
                <select
                  className={"select-box_main"}
                  id={"business-constitution-select"}
                  tabIndex={2}
                  required={true}
                  onChange={this.inputHandler}
                  value={businessDetailsData?.constitution}
                  name="constitution"
                >
                  {businessConstitutionList.map((x, y) => (
                    <option key={y + 1}>{x}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className={"col-md-4"}>
                <DatePicker
                  required={true}
                  name={"dateOfIncorporation"}
                  labelText={" Date of incorporation (DD/MM/YYYY)"}
                  defaultValue={null}
                  selected={
                    get(businessDetailsData, "dateOfIncorporation", "")
                      ? new Date(
                          get(businessDetailsData, "dateOfIncorporation", "")
                            .split("-")
                            .reverse()
                        )
                      : null
                  }
                  onChange={(e) =>
                    this.dateHandler("dateOfIncorporation", getDMY(new Date(e)))
                  }
                />
              </div>
              <div className={"col-md-4"}>
                <DatePicker
                  required={true}
                  name={"businessCommencementDate"}
                  labelText={"Business Commencement Date (DD/MM/YYYY)"}
                  selected={
                    get(businessDetailsData, "businessCommencement", "")
                      ? new Date(
                          get(businessDetailsData, "businessCommencement", "")
                            .split("-")
                            .reverse()
                        )
                      : null
                  }
                  onChange={(e) =>
                    this.dateHandler(
                      "businessCommencement",
                      getDMY(new Date(e))
                    )
                  }
                  maxDate={new Date(maxDate)}
                  defaultValue={null}
                />
              </div>
              <div className={"col-md-4"}>
                <InputLabelAnimation
                  labelText={"Contact No."}
                  required={true}
                  name={"contactNumber"}
                  maxLength={10}
                  onChange={this.inputHandler}
                  inputValue={businessDetailsData?.contactNumber}
                />
              </div>
            </div>
            <div className="row">
              <div className={"col-md-4"}>
                <InputLabelAnimation
                  labelText={"Email Id "}
                  required={true}
                  name={"email"}
                  onChange={this.inputHandler}
                  inputValue={businessDetailsData?.email}
                />
              </div>
            </div>
            {businessDetailsData?.gstDetails?.map((gstDetail, gstIndex) => {
              return (
                <div className={"content-box-inner"} key={gstDetail.gstId}>
                  <div className={"address-box"}>
                    <div className={"box-header"}>
                      <h5>GST - {gstDetail.gstin}</h5>
                      <button
                        type={"button"}
                        className={"add-btn"}
                        onClick={() => addAddress(gstIndex)}
                      >
                        <Icon icon={ic_add} size={22} /> Add Address
                      </button>
                    </div>
                  </div>
                  <div className="gstRegistration">
                    <div className="row gstRegistration-inner">
                      <div className={"col-md-4"}>
                        <DatePicker
                          maxDate={maxDate}
                          name={"dateOfGstRegistration"}
                          labelText={"Date of GST Registration (DD/MM/YYYY)"}
                          selected={
                            get(gstDetail, "dateOfGstRegistration", "")
                              ? new Date(
                                  get(gstDetail, "dateOfGstRegistration", "")
                                    .split("-")
                                    .reverse()
                                )
                              : null
                          }
                          onChange={(e) =>
                            changeInputValue(
                              getDMY(new Date(e)),
                              `gstDetails[${gstIndex}].dateOfGstRegistration`
                            )
                          }
                        />
                      </div>
                      <div className={"col-md-4"}>
                        <select
                          className={"select-box_main mt-2"}
                          id={"tax-payer-type"}
                          value={get(gstDetail, "taxpayerType", "")}
                          name={"taxpayerType"}
                          onChange={(e) =>
                            changeInputValue(
                              e.target.value,
                              `gstDetails[${gstIndex}].taxpayerType`
                            )
                          }
                        >
                          {taxPayerTypeList.map((x, y) => (
                            <option key={y + 1}>{x}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {gstDetail?.address?.map((address, addressIndex) => {
                    return (
                      <Address
                        addressTypeList={addressTypeList}
                        addressObject={address}
                        index={addressIndex}
                        changeHandler={(name, value) =>
                          updateAddress(gstIndex, addressIndex, name, value)
                        }
                        deleteAddress={() =>
                          deleteAddress(gstIndex, addressIndex)
                        }
                      />
                    );
                  })}
                  <div className="text-center mt-2">
                    <button
                      onClick={() => this.addGSTData(gstIndex)}
                      className="save-btn mb-3"
                      type="button"
                    >
                      Submit
                    </button>
                    {this.state[`gstSubmit${gstIndex}`] ? (
                      <div className="success-message-gst">
                        <Icon
                          icon={ic_check_circle}
                          size={22}
                          className="mr-1"
                        />
                        Submitted Successfully
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}

            {/* <div className="content-box-inner ">
              <div className="address-box box-border">
                <div className={"box-header box-flex"}>
                  <span className="secondary-box-heading">
                    Related Party Details
                    <span className="option">(optional)</span>
                  </span>

                  <button type={"button"} className={"btn-width add-btn"} onClick={addPartyNewRow}>
                    <Icon icon={ic_add} size={22} /> Add related party
                  </button>
                </div>
              </div>
              {partyDetailsData.map((partyDetail, index) => {
                return (
                  <PartyDetails
                    key={`party-details-${index}`}
                    partyDetail={partyDetail}
                    delete={this.clickOnPartyDelete}
                    rowIndex={index}
                    deletePartyRow={deletePartyRow}
                    changeHandler={(name, value) => partyChangeHandler(index, name, value)}
                  />
                );
              })}
            </div> */}
            <div className={"text-center"}>
              <button className={"save-btn"} type={"submit"}>
                Save & Next
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  async componentDidMount() {
    let { actions } = this.props,
      { getList } = actions;
    actions.loaderModal(true);
    getList("SET_BUSINESS_CONSTITUTION_LIST", API_GET_CONSTITUTION, "Select Business Constitution");
    getList("SET_TAXPAYER_TYPE_LIST", API_GET_TAXPAYER_TYPE, "Select Taxpayer Type");
    await getList("SET_ADDRESS_TYPE", API_GET_BUSINESS_ADDRESS_TYPE, "Select Address Type");
    actions.loaderModal(false);
  }
}

const mapStateToProps = ({ gstnAddressReducers, listData }) => ({
  businessConstitutionList: listData.businessConstitutionList,
  taxPayerTypeList: listData.taxPayerTypeList,
  addressTypeList: listData.addressTypeList,
  partyDetailsData: gstnAddressReducers.partyDetailsData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getList,
      addAddress,
      loaderModal,
      changeInputValue,
      updateAddress,
      deleteAddress,
      addPartyNewRow,
      deletePartyRow,
      partyChangeHandler,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GstnUpdatedAddress);
