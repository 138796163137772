import React, { Component } from "react";
import PropTypes from "prop-types";
import { goTo } from "../../utils/utitlity";
import { get } from "lodash";

class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.active,
    };
  }

  render() {
    const { active } = this.state,
      baseName = "/Sales",
      { match } = this.props,
      applicationNumber = get(match, "params.appNo", "");
    return (
      <div className="progressbar-wrapper">
        <ul className="progressbar">
          {/* <li
            // onClick={
            //   active > 1
            //     ? () => goTo(`${baseName}/${applicationNumber}/gstn`)
            //     : null
            // }hot fix
            className={
              active === 1 ? "active" : active > 1 ? "done cursor-p" : ""
            }
          >
            <span>
              <svg
                style={{ display: "inline-block", verticalAlign: "middle" }}
                width="32"
                height="32"
                viewBox="0 0 42.881 42.635"
              >
                <g
                  id="Group_2033"
                  data-name="Group 2033"
                  transform="translate(-356.797 -209.264)"
                >
                  <g id="money_4" transform="translate(356.797 209.264)">
                    <g id="Group_2002" data-name="Group 2002">
                      <path
                        id="Path_3611"
                        data-name="Path 3611"
                        d="M42.882,23.969a4.132,4.132,0,0,0-6.444-3.437h0s0,0,0,.007a4.348,4.348,0,0,0-.758.65l.011.011a36.794,36.794,0,0,0-3.23,3.247,4.149,4.149,0,0,0-.961-1.465c-4.091-3.6-9.009-5.065-14.647-5.065a24.822,24.822,0,0,0-5.308.568c-.05-.612-.084-.985-.084-.985H.481a83.872,83.872,0,0,0,0,17.941H11.455s.1-1.045.2-2.537a7.407,7.407,0,0,1,5.237,1.356,15.944,15.944,0,0,0,8.734,2.549,14.666,14.666,0,0,0,8.523-2.62l0,0,.091-.07a15.782,15.782,0,0,0,1.893-1.565c.465-.474,1.091-1.081,2.036-2a35.826,35.826,0,0,0,3.554-3.727l-.012,0A4.074,4.074,0,0,0,42.882,23.969Zm-2.518,1.625c-.555.549-2.9,2.968-3.536,3.589-1.647,1.617-2.413,2.373-2.777,2.793l-.005-.008c-.252.225-.516.431-.785.63a13.858,13.858,0,0,1-15.318-.032h-.016a10.072,10.072,0,0,0-6.143-1.6c.084-1.478.154-3.1.154-4.488,0-1.93-.134-4.3-.259-6.12a23.529,23.529,0,0,1,5.169-.574c5.521,0,10.4,1.825,13.394,4.683a2.21,2.21,0,0,1-1.647,3.7c-.047,0-.09-.012-.138-.015l-.005.028A21.253,21.253,0,0,1,22.5,26.5l-.687,1.8A22.518,22.518,0,0,0,28.6,30.1v-.013a4.155,4.155,0,0,0,3.96-2.949v0A32.1,32.1,0,0,1,37,22.606l0,0c.015-.015.028-.025.036-.035.255-.229.474-.419.634-.557a1.844,1.844,0,0,1,1.069-.259,2.221,2.221,0,0,1,2.218,2.219A2.775,2.775,0,0,1,40.364,25.594Z"
                        transform="translate(0 5.827)"
                        fill="#707070"
                      />
                      <path
                        id="Path_3612"
                        data-name="Path 3612"
                        d="M25.122,24.908A12.408,12.408,0,1,0,12.713,12.5,12.423,12.423,0,0,0,25.122,24.908Zm0-22.654A10.246,10.246,0,1,1,14.876,12.5,10.26,10.26,0,0,1,25.122,2.255Z"
                        transform="translate(4.323 -0.092)"
                        fill="#707070"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_2014"
                    data-name="Group 2014"
                    transform="translate(381 215.433)"
                  >
                    <path
                      id="Path_2367"
                      data-name="Path 2367"
                      d="M0,1.36c.093-.126.189-.251.28-.379Q.608.526.93.066A.13.13,0,0,1,1.05,0Q5.485,0,9.923,0H10c-.02.034-.033.059-.048.084-.274.431-.549.861-.822,1.292A.134.134,0,0,1,9,1.45q-1.058,0-2.114,0h-.1c.071.121.143.226.2.341.126.276.244.553.361.833a.074.074,0,0,0,.085.052l1.4,0L9.9,2.686l.056,0a.394.394,0,0,1-.023.046c-.3.446-.594.891-.889,1.338a.1.1,0,0,1-.1.052c-.472,0-.943,0-1.415,0-.048,0-.071.011-.084.062A3.591,3.591,0,0,1,5.365,6.67a7.13,7.13,0,0,1-2.042.579L2.9,7.308,8.253,13.5c-.052,0-.08,0-.11,0q-1.124,0-2.247,0a.177.177,0,0,1-.146-.067Q3.575,11.009,1.4,8.579c-.272-.3-.543-.607-.817-.909A.206.206,0,0,1,.523,7.52c0-.358,0-.717,0-1.074V6.323c.115,0,.223,0,.331,0a11.576,11.576,0,0,0,2.357-.175A1.949,1.949,0,0,0,3.618,6,3.818,3.818,0,0,0,4.791,5.26a2.024,2.024,0,0,0,.628-1.212.222.222,0,0,0,0-.033.2.2,0,0,0-.007-.023H.048a.366.366,0,0,1,.026-.056C.335,3.549.6,3.165.853,2.778a.145.145,0,0,1,.141-.071q2.057,0,4.115,0h.116a2.932,2.932,0,0,0-.166-.267,1.974,1.974,0,0,0-.887-.608,6.225,6.225,0,0,0-1.463-.343A18.491,18.491,0,0,0,.1,1.383c-.033,0-.066,0-.1,0A.067.067,0,0,1,0,1.36Z"
                      transform="translate(0 0)"
                      fill="#707070"
                    />
                  </g>
                </g>
              </svg>
            </span>
            <label>Program Details</label>
          </li> */}
          <li
            onClick={
              active > 2
                ? () => goTo(`${baseName}/${applicationNumber}/gstndetails`)
                : null
            }
            className={
              active === 2 ? "active" : active > 2 ? "done cursor-p" : ""
            }
          >
            <span>
              <svg
                style={{ display: "inline-block", verticalAlign: "middle" }}
                width="32"
                height="32"
                viewBox="0 0 46.479 41.971"
              >
                <g
                  id="Group_2006"
                  data-name="Group 2006"
                  transform="translate(0.25 0.25)"
                >
                  <path
                    id="Path_2719"
                    data-name="Path 2719"
                    d="M22.169,11.311h3.606a.426.426,0,0,0,.451-.451v-2.7a.426.426,0,0,0-.451-.451H22.62V5.9h2.7v.451a.451.451,0,1,0,.9,0v-.9A.426.426,0,0,0,25.776,5H22.169a.426.426,0,0,0-.451.451v2.7a.426.426,0,0,0,.451.451h3.155v1.8h-2.7V9.959a.451.451,0,0,0-.9,0v.9a.483.483,0,0,0,.451.451ZM28.48,6.8a.426.426,0,0,0,.451-.451V5.9h.9V10.86a.451.451,0,1,0,.9,0V5.9h.9v.451a.451.451,0,1,0,.9,0v-.9A.426.426,0,0,0,32.086,5H28.48a.426.426,0,0,0-.451.451v.9a.483.483,0,0,0,.451.451ZM15.859,11.311h3.606a.426.426,0,0,0,.451-.451v-2.7a.426.426,0,0,0-.451-.451h-1.8a.451.451,0,1,0,0,.9h1.352v1.8h-2.7V5.9h2.7v.451a.451.451,0,1,0,.9,0v-.9A.426.426,0,0,0,19.465,5H15.859a.426.426,0,0,0-.451.451V10.86a.483.483,0,0,0,.451.451Zm0,2.7H32.086a.451.451,0,1,0,0-.9H15.859a.426.426,0,0,0-.451.451.483.483,0,0,0,.451.451Zm-4.508,4.508H36.594a.451.451,0,1,0,0-.9H11.351a.426.426,0,0,0-.451.451.483.483,0,0,0,.451.451Zm0,2.7H36.594a.451.451,0,1,0,0-.9H11.351a.426.426,0,0,0-.451.451.483.483,0,0,0,.451.451Zm0,2.7H36.594a.451.451,0,1,0,0-.9H11.351a.426.426,0,0,0-.451.451.483.483,0,0,0,.451.451Zm0,2.7H36.594a.451.451,0,1,0,0-.9H11.351a.426.426,0,0,0-.451.451.483.483,0,0,0,.451.451Zm0,2.7H36.594a.451.451,0,1,0,0-.9H11.351a.426.426,0,0,0-.451.451.483.483,0,0,0,.451.451Zm0,2.7H36.594a.451.451,0,1,0,0-.9H11.351a.426.426,0,0,0-.451.451.483.483,0,0,0,.451.451Z"
                    transform="translate(-1.073 -0.492)"
                    fill="#707070"
                    stroke="#707070"
                    strokeWidth="0.5"
                  />
                  <path
                    id="Path_2720"
                    data-name="Path 2720"
                    d="M41.922,33.357H38.767V4.057A4.04,4.04,0,0,0,34.71,0H4.057a4.057,4.057,0,0,0,0,8.114H7.212v29.3a4.04,4.04,0,0,0,4.057,4.057H41.922a4.057,4.057,0,1,0,0-8.114ZM.9,4.057A3.194,3.194,0,0,1,4.057.9a3.042,3.042,0,0,1,3.065,2.7H4.057a.426.426,0,0,0-.451.451.426.426,0,0,0,.451.451A1.387,1.387,0,0,1,5.409,5.86,1.387,1.387,0,0,1,4.057,7.212,3.194,3.194,0,0,1,.9,4.057ZM5.86,7.212a2.254,2.254,0,0,0,0-2.7H7.212v2.7Zm2.254,30.2V4.057A4.112,4.112,0,0,0,6.581.9H34.71a3.194,3.194,0,0,1,3.155,3.155v29.3h-27.5a.451.451,0,1,0,0,.9h.9a2.985,2.985,0,0,1,2.795,1.8H10.278a.451.451,0,1,0,0,.9h4.057c0,.18.09.27.09.451a3.155,3.155,0,0,1-6.311,0ZM41.922,40.57H13.794a4.015,4.015,0,0,0,0-6.311H41.922a3.155,3.155,0,0,1,0,6.311Z"
                    fill="#707070"
                    stroke="#707070"
                    strokeWidth="0.5"
                  />
                </g>
              </svg>
            </span>
            <label>GST Details</label>
          </li>
          <li
            onClick={
              active > 3
                ? () =>
                    goTo(
                      `${baseName}/${applicationNumber}/applicantGuide?entity_name=sofocle%20technologies&constitution=public`
                    )
                : null
            }
            className={
              active === 3 ? "active" : active > 3 ? "done cursor-p" : ""
            }
          >
            <span>
              <svg
                style={{ display: "inline-block", verticalAlign: "middle" }}
                width="30"
                height="30"
                viewBox="0 0 34.756 44.221"
              >
                <path
                  id="Path_3595"
                  data-name="Path 3595"
                  d="M66.579,11.615c0-.02-.005-.04-.009-.059s0-.014,0-.02c0-.023-.011-.046-.017-.069l0-.009c-.007-.023-.015-.045-.024-.067l0-.008c-.009-.021-.018-.041-.029-.061l-.006-.011c-.01-.018-.021-.036-.032-.053l-.01-.016c-.01-.016-.022-.031-.034-.046l-.014-.018c-.014-.017-.03-.034-.045-.05l-.008-.009L55.463.239h0C55.444.22,55.424.2,55.4.185L55.39.175c-.017-.013-.033-.026-.05-.037L55.326.13C55.308.118,55.29.107,55.27.1l-.008,0C55.241.082,55.22.071,55.2.062h0c-.023-.01-.047-.018-.071-.026h0q-.037-.011-.074-.019l-.01,0c-.023,0-.046-.008-.07-.01l-.045,0-.036,0H32.643a.816.816,0,0,0-.816.816V43.4a.816.816,0,0,0,.816.816H65.767a.816.816,0,0,0,.816-.816V11.7h0s0,0,0,0C66.582,11.667,66.581,11.641,66.579,11.615ZM63.8,10.881H55.7v-8.1Zm1.154,31.708H33.459V1.632H54.07V11.7a.816.816,0,0,0,.816.816H64.951Z"
                  transform="translate(-31.827)"
                  fill="#707070"
                />
                <path
                  id="Path_3596"
                  data-name="Path 3596"
                  d="M92.611,37.959a3.394,3.394,0,1,0-3.393-3.394A3.4,3.4,0,0,0,92.611,37.959Zm0-5.156a1.762,1.762,0,1,1-1.761,1.762A1.763,1.763,0,0,1,92.611,32.8Z"
                  transform="translate(-80.684 -26.536)"
                  fill="#707070"
                />
                <path
                  id="Path_3597"
                  data-name="Path 3597"
                  d="M81.868,86.656a.816.816,0,0,0,.816-.816V84.409a1.68,1.68,0,0,1,1.678-1.678h2.614a1.68,1.68,0,0,1,1.679,1.678V85.84a.816.816,0,0,0,1.632,0V84.409a3.314,3.314,0,0,0-3.311-3.31H84.362a3.314,3.314,0,0,0-3.31,3.31V85.84A.816.816,0,0,0,81.868,86.656Z"
                  transform="translate(-73.732 -69.04)"
                  fill="#707070"
                />
                <path
                  id="Path_3598"
                  data-name="Path 3598"
                  d="M81.054,152.528a.816.816,0,0,0,.816.816h18.484a.816.816,0,1,0,0-1.632H81.87A.816.816,0,0,0,81.054,152.528Z"
                  transform="translate(-73.734 -129.152)"
                  fill="#707070"
                />
                <path
                  id="Path_3599"
                  data-name="Path 3599"
                  d="M100.354,232.915H81.87a.816.816,0,0,0,0,1.632h18.484a.816.816,0,1,0,0-1.632Z"
                  transform="translate(-73.734 -198.281)"
                  fill="#707070"
                />
                <path
                  id="Path_3600"
                  data-name="Path 3600"
                  d="M100.354,192.313H81.87a.816.816,0,0,0,0,1.632h18.484a.816.816,0,0,0,0-1.632Z"
                  transform="translate(-73.734 -163.716)"
                  fill="#707070"
                />
              </svg>
            </span>
            <label>Applicant Details</label>
          </li>
          <li
            onClick={
              active > 4
                ? () => goTo(`${baseName}/${applicationNumber}/banking`)
                : null
            }
            className={
              active === 4 ? "active" : active > 4 ? "done cursor-p" : ""
            }
          >
            <span>
              <svg
                width="30"
                height="30"
                viewBox="0 0 45.381 45.347"
                style={{ display: "inline-block", verticalAlign: "middle" }}
              >
                <g
                  id="Group_2001"
                  data-name="Group 2001"
                  transform="translate(0.775 0.762)"
                >
                  <path
                    id="Path_3608"
                    data-name="Path 3608"
                    d="M41.844,42.718H1.993a1.993,1.993,0,0,0,0,3.986h39.85a1.993,1.993,0,0,0,0-3.986Z"
                    transform="translate(0 -2.869)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  />
                  <path
                    id="Path_3609"
                    data-name="Path 3609"
                    d="M4.662,35.736a1.994,1.994,0,0,0,0,3.987H39.531a1.994,1.994,0,0,0,0-3.987h-.5V17.8h.5a1,1,0,1,0,0-1.991H4.662a1,1,0,1,0,0,1.991h.5V35.735h-.5ZM35.048,17.8V35.735H29.071V17.8Zm-9.963,0V35.735H19.107V17.8Zm-15.94,0h5.977V35.735H9.145Z"
                    transform="translate(-0.179 -1.069)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  />
                  <path
                    id="Path_3610"
                    data-name="Path 3610"
                    d="M1.993,12.962h39.87a1.993,1.993,0,0,0,.647-3.879L22.737.186a2,2,0,0,0-1.635,0L1.176,9.152a1.993,1.993,0,0,0,.817,3.81Z"
                    transform="translate(0 -0.012)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1.5"
                  />
                </g>
              </svg>
            </span>
            <label>Banking Details</label>
          </li>
          <li
            onClick={
              active > 5
                ? () => goTo(`${baseName}/${applicationNumber}/documents`)
                : null
            }
            className={
              active === 5 ? "active" : active > 5 ? "done cursor-p" : ""
            }
          >
            <span>
              <svg
                style={{ display: "inline-block", verticalAlign: "middle" }}
                width="28"
                height="28"
                viewBox="0 0 34.596 44.98"
              >
                <path
                  id="upload"
                  d="M74.088,10.78,62.644,1.363A6.68,6.68,0,0,0,58.815,0H45.622A2.9,2.9,0,0,0,42.7,2.874c0,.38.02,37.957.02,38.154a2.334,2.334,0,0,0,2.442,2.438L73,43.48a2.825,2.825,0,0,0,2.8-2.907V14.552A5.263,5.263,0,0,0,74.088,10.78ZM58.815.122h0ZM66.769,27.9a.862.862,0,0,1-.8.431H62.058v9.33a1.109,1.109,0,0,1-1.076,1.127H57.623a1.077,1.077,0,0,1-1.037-1.127v-9.33H52.637A.816.816,0,0,1,51.931,27l6.594-9.409a.925.925,0,0,1,1.554,0L66.674,27A.861.861,0,0,1,66.769,27.9ZM62.418,13.857c.056,0,.114,0,.173,0h0Q62.5,13.862,62.418,13.857Zm.173-.117a1.906,1.906,0,0,1-1.992-2V3.765l12.643,9.976Z"
                  transform="translate(-41.953 0.75)"
                  fill="none"
                  stroke="#707070"
                  strokeWidth="1.5"
                />
              </svg>
            </span>
            <label>Upload Documents</label>
          </li>
        </ul>
      </div>
    );
  }
}

ProgressBar.propTypes = {
  active: PropTypes.number.isRequired,
};

export default ProgressBar;
