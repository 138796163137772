import React, { Component, Fragment } from "react";
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_notifications } from "react-icons-kit/md/ic_notifications";
import { ic_person } from "react-icons-kit/md/ic_person";
import { phone } from "react-icons-kit/fa/phone";
import { Link } from "react-router-dom";
import { deleteCookie } from "../../utils/utitlity";
import { connect } from "react-redux";
import Select from "react-select";
import SearchIcon from "../../../src/assets/images/SearchIcon.svg";
import { goTo } from "../../utils/utitlity";
import {
  setUserDetails,
  setUserLoginStatus,
  loaderModal,
} from "../../store/actions";
import { get } from "lodash";
import { GET } from "../../utils/webAPI.service";
import { bindActionCreators } from "redux";
import {
  changeValue,
  fetchNotifications,
  clearHeaderState,
} from "./header.action";
import {
  API_USER_LOGOUT,
  API_GET_MARK_NOTIFICATION_READ,
} from "../../utils/APIUrls";
import { setSearchCriteria } from "../../views/NewCpoPanel/newCpoPanel.actions";

import { OverlayTrigger, Popover } from "react-bootstrap";
import { ic_access_time } from "react-icons-kit/md/ic_access_time";
import { SearchFilter } from "./SearchFilter";
import {
  getOPSUserFilteredApplication,
  setPageNumber,
} from "../../views/NewCpoPanel/newCpoPanel.actions";
import {
  getOPSAdminFilteredApplication,
  setSearchCriteria as setAdminSearchCriteria,
  setPageNumber as setAdminPageNumber,
  getCPOAdminData,
} from "../../views/CPOPanel/CPOAdmin.action";
import { SearchAdminFilter } from "../../views/CPOPanel/SearchFilter";
import {
  setInputField,
  changeValue as headerChangeValue,
} from "./header.action";
class Header extends Component {
  state = {
    inputField: false,
    isNotificationRead: false,
    newFilterOption: [],
  };
  userLogout = () => {
    let { setUserDetails, setUserLoginStatus, loaderModal, headerChangeValue } =
      this.props.actions;
    let { history } = this.props;
    headerChangeValue("searchInput", "");
    headerChangeValue("searchFilter", "");
    this.props.actions.clearHeaderState();
    loaderModal(true);
    GET(API_USER_LOGOUT)
      .then(({ data }) => {
        loaderModal(false);
        window.location.reload(); //for hard refresh to setlocal state on initial state
        deleteCookie("token");
        setUserLoginStatus(false);
        setUserDetails({});
        history.push("/");
      })
      .catch((err) => console.log(err));
  };

  handleChange = (e) => {
    let { actions } = this.props;
    let { changeValue } = actions;
    const value = e.target.value;
    const name = e.target.name;
    changeValue(name, value);
  };

  searchBy = (value, action) => {
    let { setInputField } = this.props.actions;
    let { actions } = this.props;
    let { changeValue } = actions;
    changeValue(action.name, value);
    setInputField(true);
  };
  colorCode = {
    Submit: "#001061",
  };
  getHomeUrl = (arrPath) => {
    let routeUrl = `/${arrPath[1]}/dashboard`;
    const { userDetails } = this.props;
    if (arrPath[1] === "Admin") {
      routeUrl = "/Admin";
    } else if (arrPath[1] === "faq" || arrPath[1] === "help") {
      if (
        userDetails.roleName === "UW" ||
        userDetails.roleName === "UW_ADMIN"
      ) {
        routeUrl = "/Credit/dashboard";
      } else if (userDetails.roleName === "CPO_USER") {
        routeUrl = "/CPODashboard/User";
      } else if (userDetails.roleName === "CPO_ADMIN") {
        routeUrl = "/cpopanelAdmin";
      } else if (userDetails.roleName === "ADMIN") {
        routeUrl = "/Admin";
      } else {
        routeUrl = "/Sales/dashboard";
      }
    } else if (userDetails.roleName === "CPO_USER") {
      routeUrl = "/CPODashboard/User";
    } else if (userDetails.roleName === "CPO_ADMIN") {
      routeUrl = "/cpopanelAdmin";
    } else if (userDetails.roleName === "CLUSTER_USER") {
      routeUrl = "/cluster/dashboard";
    }
    return routeUrl;
  };
  readNotification = () => {
    !this.state.isNotificationRead &&
      GET(API_GET_MARK_NOTIFICATION_READ(this.props.userDetails.userId))
        .then(({ data }) => {
          if (data.status === "success") {
            this.setState({ isNotificationRead: true });
          }
        })
        .catch((err) => {
          throw err;
        });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.searchByFiltersHandler();
  };

  render() {
    let url = get(this, "props.location.pathname", "").split("/");
    const { inputField } = this.props;
    let {
        isLogin,
        userDetails,
        filterOptions,
        searchInput,
        searchFilter,
        notificationData = [],
        getOPSUserFilteredApplication,
      } = this.props,
      { newFilterOption } = this.state,
      [firstName, lastName] = "",
      seenNotification = notificationData.seen || [],
      unSeenNotification = notificationData.unSeen || [];
    newFilterOption = [
      ...filterOptions,
      url[1] === "Credit" && {
        label: "Application Id",
        value: "Application Number",
      },
      url[1] === "Credit" && {
        label: "Application Name",
        value: "APPLICATION_NAME",
      },
    ].filter(Boolean);
    // The filter(Boolean) removes falsy values (null, undefined, false) from the array
    if (isLogin) {
      firstName =
        userDetails.firstName.charAt(0).toUpperCase() +
          userDetails.firstName.slice(1) || "";
      lastName =
        userDetails.lastName.charAt(0).toUpperCase() +
          userDetails.lastName.slice(1) || "";
    }
    let arrPath = get(this, "props.location.pathname", "").split("/"),
      activeUrl = arrPath[2],
      routeUrl = "";

    const currentTab = get(this, "props.location.search", "")
      ?.split("?")[1]
      ?.split("=")[1];
    return (
      <header className={"header-wrapper"}>
        <Navbar>
          <Navbar.Header>
            <Navbar.Brand>
              {isLogin ? (
                <div className="logo" onClick={this.forceUpdate}>
                  <img
                    className={"img-responsive"}
                    src={require("../../assets/images/ship-rocket-logo.png")}
                    alt="logo"
                  />
                </div>
              ) : (
                <div className="logo">
                  <img
                    className={"img-responsive"}
                    src={require("../../assets/images/ship-rocket-logo.png")}
                    alt="logo"
                  />
                </div>
              )}
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            {isLogin && activeUrl === "User" ? (
              <span className="mt-5">
                {" "}
                <SearchFilter currentTab={currentTab} {...this.props} />
              </span>
            ) : (
              ""
            )}
            {isLogin && arrPath[1] === "cpoPanelAdmin" ? (
              <span>
                <SearchAdminFilter {...this.props} currentTab={currentTab} />
              </span>
            ) : (
              ""
            )}

            <Nav pullRight navbar>
              {/* 
              {/* */}
              {/* {isLogin && activeUrl !== "dashboard" ? (
                <NavItem title={"Home"} onClick={() => goTo(routeUrl)}>
                  Home
                </NavItem>
              ) : null} */}

              {
                isLogin && activeUrl === "dashboard" ? (
                  <>
                    <form onSubmit={this.handleSubmit}>
                      <div className="search-wrapper">
                        <div className="search-border">
                          <div className="search-form">
                            <select
                              className="select text-secondary"
                              name="searchFilter"
                              required
                              value={searchFilter}
                              onChange={(e) =>
                                this.searchBy(e.target.value, {
                                  name: "searchFilter",
                                })
                              }
                              defaultValue=""
                            >
                              <option value="" disabled>
                                Search By
                              </option>
                              {newFilterOption.map((element, index) => (
                                <option key={index} value={element.value}>
                                  {element.label}
                                </option>
                              ))}
                            </select>
                            <input
                              required
                              name="searchInput"
                              placeholder="Type Search"
                              disabled={!inputField}
                              value={searchInput}
                              className="search"
                              onChange={this.handleChange}
                            />
                            <button type="submit" className="submit">
                              <img src={SearchIcon} alt="Search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </>
                ) : null
                //  (
                //   <NavItem title={"FAQ's"} onClick={() => goTo("/faq")}>
                //     FAQ's
                //   </NavItem>
                // )
              }
              {/* {!isLogin && activeUrl !== "dashboard" ? (
                <NavItem title={"Help Line Number"}>
                  <Icon icon={phone} size={22} />
                  91 11-41716192
                </NavItem>
              ) : null} */}
              {/* {activeUrl !== "dashboard" ? (
                <NavItem title={"Home"} onClick={() => goTo("/help")}>
                  Help
                </NavItem>
              ) : null} */}
              {/* {!isLogin ? (
                <NavItem title={"Login"} onClick={() => goTo("/login")}>
                  Login
                </NavItem>
              ) : null} */}
              {isLogin ? (
                <Fragment>
                  {/* <NavItem title={"Notifications"}>
                    <OverlayTrigger
                      trigger="click"
                      placement={"bottom"}
                      overlay={
                        <Popover
                          id="notification"
                          title="Notifications"
                          className="notification"
                        >
                          <div className="blue-background">
                            {[...unSeenNotification, ...seenNotification].length
                              ? "NEW"
                              : "No Notifications"}
                          </div>
                          {[...unSeenNotification, ...seenNotification].map(
                            (notification) => {
                              return (
                                <div
                                  className="notification__wrapper"
                                  key={notification.recordId}
                                >
                                  <div
                                    className="identifier-circle"
                                    style={{
                                      backgroundColor:
                                        this.colorCode[notification.status],
                                    }}
                                  >
                                    <p>{notification.status[0]}</p>
                                  </div>
                                  <div className="right-content">
                                    <div className="notification-message">
                                      {notification.alertMessage}
                                    </div>
                                    <div className="time-period__wrapper">
                                      <div className="time">
                                        <Icon
                                          icon={ic_access_time}
                                          size={16}
                                          className="mr-1"
                                        />
                                        <span>
                                          {notification.date &&
                                            notification.date.split(" ")[1]}
                                        </span>
                                      </div>
                                      <div className="date">
                                        {notification.date &&
                                          notification.date.split(" ")[0]}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </Popover>
                      }
                      rootClose
                    >
                      <span className="notification-icon__wrapper">
                        <Icon
                          icon={ic_notifications}
                          size={26}
                          onClick={this.readNotification}
                        />
                        {unSeenNotification.length !== 0 ? (
                          <>
                            <div className="new-notification__circle"></div>
                            <div className="new-notification__circle circle1"></div>
                            <div className="new-notification__circle circle2"></div>
                            <div className="new-notification__circle circle3"></div>
                          </>
                        ) : null}
                      </span>
                    </OverlayTrigger>
                  </NavItem> */}
                  <NavDropdown
                    noCaret
                    title={<Icon icon={ic_person} size={28} />}
                    id="user-dropdown"
                  >
                    <MenuItem title={`${firstName} ${lastName}`}>
                      {firstName} {lastName}
                    </MenuItem>
                    <MenuItem divider />
                    <MenuItem onClick={this.userLogout}>Logout</MenuItem>
                  </NavDropdown>
                </Fragment>
              ) : null}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    );
  }
  // componentDidMount() {
  //   let { actions, userDetails } = this.props,
  //     { fetchNotifications } = actions;
  //   // userDetails.userId && fetchNotifications(userDetails.userId);
  // }
}

Header.defaultProps = {
  filterHandler: () => {},
};

const mapStateToProps = ({ data, header, CPOUserReducer }) => ({
  userDetails: data.userDetails,
  isLogin: data.isLogin,
  searchInput: get(header, "form.values.searchInput", ""),
  searchFilter: get(header, "form.values.searchFilter", ""),
  filterOptions: header.filterOptions,
  filterHandler: header.filterHandler,
  notificationData: header.notificationData,
  cpoUserApplicationData: CPOUserReducer.cpoUserApplicationData,
  loaderModal: data.loaderModal,
  inputField: header.inputField,
  userDetails: data.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserLoginStatus,
      setUserDetails,
      loaderModal,
      changeValue,
      fetchNotifications,
      getOPSUserFilteredApplication,
      getOPSAdminFilteredApplication,
      setInputField,
      headerChangeValue,
      clearHeaderState,
      setSearchCriteria,
      setPageNumber,
      setAdminSearchCriteria,
      setAdminPageNumber,
      getCPOAdminData,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
