import React, { Component } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import { Icon } from "react-icons-kit";
import { ic_visibility } from "react-icons-kit/md/ic_visibility";
import { ic_visibility_off } from "react-icons-kit/md/ic_visibility_off";

class InputLabelAnimation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldActive: !!props.inputValue,
      isPasswordVisible: false,
    };
  }

  activateField = () => {
    this.setState({
      fieldActive: true,
    });
  };

  disableField = (e) => {
    !e.target.value &&
      this.setState({
        fieldActive: false,
      });
  };

  updateInputValue = (e) => {
    const { onChange } = this.props;
    onChange(e);
    this.activateField(e);
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      isPasswordVisible: !prevState.isPasswordVisible,
    }));
  };

  render() {
    const { fieldActive, isPasswordVisible } = this.state;
    const {
      inputClass,
      type,
      labelText,
      required,
      name,
      inputValue,
      disabled,
      minLength,
      maxLength,
      min,
      max,
      boxClass,
      readOnly,
      tabIndex,
      title,
      placeholder,
      mask,
    } = this.props;

    const inputType = type === "password" && isPasswordVisible ? "text" : type;

    return (
      <div
        className={
          disabled
            ? `input-box input-disabled ${boxClass}`
            : `input-box ${boxClass}`
        }
      >
        <label
          onClick={() => this.inputField.focus()}
          className={fieldActive ? "field-active" : ""}
        >
          {labelText}
          {required ? <span>&nbsp;*&nbsp;</span> : null}
        </label>
        <div className="input-container">
          <InputMask
            mask={mask}
            maskChar={null}
            placeholder={placeholder}
            inputRef={(ref) => (this.inputField = ref)}
            className={`floating-label ${inputClass}`}
            type={inputType}
            value={inputValue}
            onFocus={this.activateField}
            onBlur={this.disableField}
            onChange={this.updateInputValue}
            required={required}
            name={name}
            disabled={disabled}
            maxLength={maxLength || null}
            minLength={minLength || null}
            max={max || null}
            min={min || null}
            readOnly={readOnly}
            tabIndex={tabIndex}
            title={title}
            style={{ paddingRight: "40px" }}
          />
          {type === "password" && (
            <button
              type="button"
              className="toggle-password-visibility"
              onClick={this.togglePasswordVisibility}
              aria-label={isPasswordVisible ? "Hide password" : "Show password"}
            >
              <Icon
                icon={isPasswordVisible ? ic_visibility_off : ic_visibility}
                size={20}
              />
            </button>
          )}
        </div>
      </div>
    );
  }
}

InputLabelAnimation.defaultProps = {
  inputClass: "input-field",
  type: "text",
  required: false,
  labelText: "",
  name: "",
  inputValue: "",
  onChange: () => {},
  disabled: false,
  readOnly: false,
  minLength: 0,
  maxLength: 0,
  min: 0,
  max: 0,
  boxClass: "",
  tabIndex: -1,
  title: "",
  placeholder: "",
  mask: "",
};

InputLabelAnimation.propTypes = {
  inputClass: PropTypes.string,
  type: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  boxClass: PropTypes.string,
  readOnly: PropTypes.bool,
  tabIndex: PropTypes.number,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  mask: PropTypes.string,
};

export default InputLabelAnimation;
